import React, { ReactNode, Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AuthService from '../Common/AuthService';
import PrivateRoute from './PrivateRoute';

import AppWrapper from './AppWrapper';
import Login from '../Authentication/Login';
import Logout from '../Authentication/Logout';
import Admin from '../Admin/Admin';
import Tracking from '../Tracking/Tracking';
import PublicHolidays from '../PublicHolidays/PublicHolidays';

class AppRouter extends Component {
	private authService: AuthService;

	public constructor(props: object) {
		super(props);
		this.authService = new AuthService();
	}

	public render(): ReactNode {
		return (
			<BrowserRouter>
				<Switch>
					<Route  path='/login' component={Login} />
					<PrivateRoute mustBeAdmin={false} path='/logout' component={Logout} />
					<PrivateRoute mustBeAdmin={false}  path={""} component={AppWrapper} />
					<PrivateRoute mustBeAdmin={true} component={Admin} path='/admin' />
					<PrivateRoute mustBeAdmin={false} component={Tracking} path='/tracking' />
					<PrivateRoute mustBeAdmin={false} component={Tracking} path='/' />
					<PrivateRoute mustBeAdmin={true} component={PublicHolidays} path='/publicHolidays' />
				</Switch>
			</BrowserRouter>
		);
	}
}

export default AppRouter;
