import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const editUserStyles = (_: Theme): StyleRules<string> => createStyles({
	dialogInput: {
		padding: "10px",
		flexGrow: 2,
		flexBasis: "200px",
		width: "50%",
		float: "left",
		margin: 0
	},
	weekdayInput: {
		float: "left",
		padding: "10px"
	},
	inputGroup: {
		display: "flex"
	},
	timeExceptionGroup: {
		display: "block"
	},
	timeExceptionInput: {
		float: "left"
	},
	selectMenu: {
		float:"right",
		width: "200px",
	},
	listBlock: {
		marginTop: '48px'
	},
	buttonContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '16px'
	},
	progressContainer: {
		minHeight: '50px'
	},
	progress: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block'
	},
	entryDate: {
		width: '50%',
		paddingRight: '10px'
	}
});

export default editUserStyles;
