import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const trackingTableRowStyles = (theme: Theme): StyleRules<string> => createStyles({
	dayOff:{
		backgroundColor: "#e0e0e0",
	},
	exceededWorkingHours: {
		backgroundColor: "#ff7980",
		borderColor: "#7d0000"
	},
	negativeHours: {
		color: "#F00"
	},
	selectError: {
		color: "#f44336"
	},
	selectMenu: {
		margin: "10px"
	},
	worktimeErrorIcon: {
		display: "inline-block",
		justifyContent: "center",
		alignitems: "center",
		margin: "-7px",
		marginRight: "0px"
	},
	currentDay: {
		boxShadow: "0px 0px 5px 0px #2b3c63",
		border: "2px solid #2b3c63"
	},
	'td': {
		borderCollapse: 'collapse',
		border: "1px solid black",
		borderSpacing: 0,
		textAlign: "end",
		paddingRight: "4px",
		maxWidth: "100px",
		padding: "14px 3px 14px 16px"
	},
	tableCell:{
		padding: "14px 20px 14px 16px"
	},
	'table': {
		borderCollapse: 'collapse',
		border: "1px solid black",
		borderSpacing: 0,
		margin: "auto",
		marginTop: "20px"
	},
	rowRoot: {
		'& td':{
			borderCollapse: 'collapse',
			border: "1px solid black",
			borderSpacing: 0,
			textAlign: "end",
			paddingRight: "4px",
			maxWidth: "100px",
			padding: "14px 25px 14px 16px",
			background: "red"
		}
	},
});

export default trackingTableRowStyles;
