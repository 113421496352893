import React, { Component, ReactNode } from 'react';

import AuthService, { CurrentUserResponse } from '../Common/AuthService';
import { Dialog, Paper, WithStyles, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';


import Button from '@material-ui/core/Button';
import publicHolidayStyles from './PublicHolidayStyles';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditPublicHolidayModal from './EditPublicHolidayModal/EditPublicHolidayModal';
import PublicHolidayModel from './PublicHoliday.Model';
import moment from 'moment';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import PublicHolidayService from '../Common/PublicHolidayService';


interface State {
	email: string;
	time: Date;
	dialogOpen: boolean;
	menuOpen: number;
	menuAnchor: Element | null;
	loggedOnUserIsAdmin: boolean;
	selectedPublicHoliday: PublicHolidayModel;
	publicHolidays: PublicHolidayModel[];
	publicHolidayTableRows: ReactNode[];
}

interface Props extends WithStyles<typeof publicHolidayStyles>{
	switchVisibility: Function;
}

class PublicHolidays extends Component<Props, State> {
	private authService: AuthService;
	private publicHolidayService: PublicHolidayService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.publicHolidayService = new PublicHolidayService();

		this.state = {
			email: "",
			time: new Date(),
			dialogOpen: false,
			menuOpen: -1,
			menuAnchor: null,
			loggedOnUserIsAdmin: false,
			selectedPublicHoliday: {
				date: new Date(),
				halfDay: false,
				name: "",
			},publicHolidays: [],
			publicHolidayTableRows: []
		};

		this.loadPublicHolidays();
		this.checkAdminState();
	}


	public checkAdminState(): void {
		this.authService.me().then((user: CurrentUserResponse): void => {
			if(user.data.isAdmin !== undefined){
				this.setState({loggedOnUserIsAdmin: user.data.isAdmin});
			}
		});
	}

	public loadPublicHolidays(): void {
		this.publicHolidayService.getAllPublicHolidays().then((value: PublicHolidayModel[]): void => {
			this.setState({publicHolidays: value});
			this.buildPublicHolidayTable();
		});
	}

	public buildPublicHolidayTable(): void {

		const nodes: ReactNode[] = [];
		const options = ["Bearbeiten", "Löschen"];

		this.state.publicHolidays.forEach((publicHoliday: PublicHolidayModel): void => {
			nodes.push(
				<TableRow key={publicHoliday.name}>
					<TableCell>
						{publicHoliday.name}
					</TableCell>
					<TableCell>
						{moment(publicHoliday.date).format('dddd DD MMMM YYYY')}
					</TableCell>
					<TableCell>
						<PopupState variant="popover" popupId="edit-exceptions-menu" key={publicHoliday.name}>
							{(popupState: Window): ReactNode => (
								<React.Fragment>
									<IconButton {...bindTrigger(popupState)}>
										<MoreVertIcon />
									</IconButton>
									<Menu {...bindMenu(popupState)}>
										{options.map((option: string): ReactNode =>(<MenuItem key={option} onClick={(): void =>{
											if(option === "Bearbeiten"){
												this.setState({selectedPublicHoliday: publicHoliday});
												this.handleModal();
											}else if(option === "Löschen"){
												//call delete function
												this.publicHolidayService.deletePublicHoliday(publicHoliday.name).then((): void =>{
													this.loadPublicHolidays();
												});
											}
											popupState.close();
										}}>
											{option}
										</MenuItem>))}
									</Menu>
								</React.Fragment>
							)}
						</PopupState>
					</TableCell>
				</TableRow>
			);
		});

		this.setState({ publicHolidayTableRows: nodes });
	}

	public handleModal(): void {
		this.setState({dialogOpen: !this.state.dialogOpen});
		this.loadPublicHolidays();
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div>
				<div className={this.props.classes.headerContainer}>
					<h1>Feiertage</h1>

					<Button
						onClick={(): void => {
							this.setState({selectedPublicHoliday: {name: "", date: new Date(), halfDay: false}});
							this.showEditHolidayModal();
						}}
						className={this.props.classes.newUserButton}
						variant="contained"
						color="secondary"
					>
				Neuen Feiertag anlegen
					</Button>
				</div>

				<Paper>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Datum</TableCell>
								<TableCell>Optionen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.publicHolidayTableRows}
						</TableBody>
					</Table>
				</Paper>

				<Dialog open={this.state.dialogOpen} onClose={(): void =>{
					this.loadPublicHolidays();
				}}>
					<EditPublicHolidayModal
						isVisible={this.handleModal.bind(this)}
						selectedPublicHoliday={this.state.selectedPublicHoliday}
						loadPublicHolidays={this.loadPublicHolidays.bind(this)}
					/>
				</Dialog>
			</div>
		);
	}

	private showEditHolidayModal(): void {
		this.handleModal();
	}
}

export default withStyles(publicHolidayStyles)(PublicHolidays);
