import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';


const vacationPrinterStyles = (_: Theme): StyleRules<string> =>
	createStyles({
		root: {
			'& table': {
				borderSpacing: 0,
				width: "90%",
				margin: "auto",
				textAlign: "start",
				borderCollapse: 'collapse',
				fontSize: "11px",

				'& td': {
					borderCollapse: 'collapse',
					border: "1px solid black",
				}

			},
		},
		printerOverlay: {
			height: "100%",
			zIndex: 65536,
			position: "absolute",
			background: "white",
			width: "100%",
			top: "0",
			left: "0",
			bottom: "0",
			right: "0"
		},
		printLogo:{
			width: "100px",
			left: "5%",
			position: "relative"
		},
		generalInfo: {
			borderCollapse: 'collapse',
			display: "flex",
			justifyContent: "space-between",
			width: "90%",
			margin: "auto",
			marginTop: 15,
			marginBottom: 10
		},
	});

export default vacationPrinterStyles;
