import React, { Component, ReactNode } from 'react';

import {
	WithStyles,
	withStyles
} from '@material-ui/core';

import TimeTrackingMonth from '../../Tracking/DataModels/TimeTrackingMonth';
import TimeTrackingDay from '../../Tracking/DataModels/TimeTrackingDay';
import vacationEmployeeMonthPrinterStyles from './VacationEmployeeMonthPrinterStyles';

interface Props extends WithStyles<typeof vacationEmployeeMonthPrinterStyles> {
	timeTrackingMonth: TimeTrackingMonth;
}

class VacationEmployeeMonth extends Component<Props> {
	public constructor(props: Props) {
		super(props);
	}

	private getSumVacationDays(): number {
		let days = 0;
		for (const day of this.props.timeTrackingMonth.days) {
			if (day.usesZa === 1) {
				days++;
			}
		}
		return days;
	}

	private getSumVacatioDays(): number {
		let days = 0;
		for (const day of this.props.timeTrackingMonth.days) {
			if (day.usesZa === 2) {
				days++;
			}
		}
		return days;
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return(
			<tr className={classes.tableMonth}>
				<td>
					<div className={classes.month}>
						<div><b>{`${this.props.timeTrackingMonth.userName} (${this.props.timeTrackingMonth.userEmail})`}</b></div>
						<div>Summe Urlaubs- und Krankentage:</div>
						<div className={classes.monthValues}>
							<div>Summe Urlaubstage:<b> {this.getSumVacationDays()} Tage</b></div>
							<div>Summe Krankentage:<b> {this.getSumVacatioDays()} Tage</b></div>
						</div>
						<div>Einzelne Urlaubs- und Krankentage:</div>
						{this.props.timeTrackingMonth.days.filter((day: TimeTrackingDay) => day.usesZa === 1 || day.usesZa === 2).map((day: TimeTrackingDay): ReactNode => (
							<React.Fragment key={day.date.toString()}>
								<span>{day.date.format('DD. MM. YYYY')}</span>
								{day.usesZa === 1 &&
									<span className={classes.dayDetail}>{'Urlaub'}</span>
								}
								{day.usesZa === 2 &&
									<span className={classes.dayDetail}>{'Krankenstand'}</span>
								}
								<hr></hr>
							</React.Fragment>
						))}
					</div>
				</td>
			</tr>
		);
	}
}

export default withStyles(vacationEmployeeMonthPrinterStyles)(VacationEmployeeMonth);
