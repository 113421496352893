import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';


const trackingPrinterStyles = (theme: Theme): StyleRules<string> =>
	createStyles({
		root: {
			'& table': {
				borderSpacing: 0,
				width: "90%",
				margin: "auto",
				textAlign: "end",
				borderCollapse: 'collapse',
				fontSize: "11px",
				'& tr': {
					'& td:first-child':{
						fontWeight: "bold"
					},
					'& td:empty':{
						/*
						* To prevent a thin line if no comment was made you can activate the given rule
						* However if cells have comments only the cells with comments get shown
						* */
						//display: "contents"
					},
					'& td': {
						borderCollapse: 'collapse',
						border: "1px solid black",
					}
				},'& tfoot':{
					borderLeftStyle: "hidden",
					borderRightStyle: "hidden"
				}
			},
		},
		dayOff:{
			backgroundColor: "#e0e0e0",
		},
		exceededWorkingHours: {
			backgroundColor: "#ff7980",
			borderColor: "#7d0000"
		},
		publicHoliday:{
			backgroundColor: "#97beff"
		},
		printerOverlay: {
			height: "100%",
			zIndex: 65536,
			position: "absolute",
			background: "white",
			width: "100%",
			top: "0",
			left: "0",
			bottom: "0",
			right: "0"
		},
		printLogo:{
			width: "100px",
			left: "5%",
			position: "relative"
		},
		boldCell: {
			border: "1px solid black",
			borderSpacing: 0,
			borderCollapse: 'collapse',
			textAlign: "end"
		},
		textRight: {
			borderCollapse: 'collapse',
			textAlign: "end",
			borderSpacing: 0,
			fontWeight: 900
		},
		textMiddle:{
			borderCollapse: 'collapse',
			textAlign: 'center',
			borderSpacing: 0,
			fontWeight: 900
		},
		generalInfo: {
			borderCollapse: 'collapse',
			display: "flex",
			justifyContent: "space-between",
			width: "90%",
			margin: "auto",
			marginTop: 15,
			marginBottom: 10
		},
		numericCell: {
			borderCollapse: 'collapse',
			textAlign: "end"
		},
		summaryCardWrapper: {
			width: "fit-content",
			justifyContent: "space-between",
			display: "block",
			margin: "15px auto",
		},
		timeCell: {
			width: "48px"
		},
		sumCell: {
			width: "64px"
		},
		dayCell: {
			width: "100px"
		},
		commentCell: {
			width: '40%',
		}
	});

export default trackingPrinterStyles;
