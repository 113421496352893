import React, { Component, ReactNode } from 'react';

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { UserDataModel } from './../UserData.Model';
import Button from '@material-ui/core/Button';
import worktimeExceptionStyles from './worktimeExceptionStyles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import moment from 'moment';
import 'moment/locale/de-at.js';


interface State {
	time: Date;
	isVisible: Function;
	dialogOpen: boolean;
	daysHours: {date: Date; hours: number | null}[];
	workTimeExceptionToEdit: {date: Date; hours: number}[];
	confirmationDialogOpen: boolean;
}

interface Props extends WithStyles<typeof worktimeExceptionStyles> {
	selectedYear: number;
	selectedMonth: number;
	isVisible: Function;
	workTimeException: {date: Date; hours: number}[] | null;
	handleWorkTimeExceptions: Function;
	selectedUser: UserDataModel;
}

class WorkTimeExceptionModal extends Component<Props, State> {
	public users: UserDataModel[] = [];

	public constructor(props: Props) {
		super(props);
		this.state = {
			time: new Date(),
			isVisible: props.isVisible,
			dialogOpen: true,
			daysHours: [],
			workTimeExceptionToEdit: this.getWorkTimeExceptionToSet(props.workTimeException),
			confirmationDialogOpen: false
		};
	}

	public componentDidMount(): void {
		this.createDaysHoursDataStruct();
	}

	private getWorkTimeExceptionToSet(newWorkTimeException: {date: Date; hours: number}[] | null): {date: Date; hours: number}[] {
		if (newWorkTimeException) {
			return newWorkTimeException;
		} else {
			return [];
		}
	}

	private saveWorktimeExceptions(confirmed: boolean): void {
		if (!this.props.workTimeException && this.exceptionsExist() && !confirmed) {

			this.setState({confirmationDialogOpen: true});

		} else {
			const newWorkTimeExceptions: {date: Date; hours: number}[] = [];
			for (const entry of this.state.daysHours as {date: Date; hours: number}[]) {

				if(entry.hours !== 0 && entry.hours !== null){
					newWorkTimeExceptions.push(entry);
				}
			}

			this.props.handleWorkTimeExceptions(newWorkTimeExceptions);
			this.closeDialog();
		}

	}

	private handleOverrideConfirm(): void {
		this.closeConfirmationDialog();
		this.saveWorktimeExceptions(true);
	}

	private closeDialog(): void {
		if (this.props.workTimeException === null) {
			this.setState({workTimeExceptionToEdit: this.getWorkTimeExceptionToSet(null)});
		}
		this.props.isVisible(false);
	}

	private closeConfirmationDialog(): void {
		this.setState({confirmationDialogOpen: false});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog
				aria-labelledby="customized-dialog-title"
				open={this.state.dialogOpen}
				maxWidth={false}
			>
				<DialogTitle id="customized-dialog-title">
				Ausnahme für {WorkTimeExceptionModal.getMonthName(this.props.selectedMonth)} {this.props.selectedYear} {this.props.workTimeException ? 'bearbeiten' : 'hinzufügen'}
				</DialogTitle>
				<DialogContent className={classes.dialogBox}>
					{
						this.printDayTable()
					}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.closeDialog.bind(this)}
						variant="outlined"
						color="secondary"
					>
					Abbrechen
					</Button>
					<Button
						onClick={this.saveWorktimeExceptions.bind(this, false)}
						variant="contained"
						color="secondary"
					>
					Speichern
					</Button>
				</DialogActions>
				<Dialog
					open={this.state.confirmationDialogOpen}
					maxWidth={'xs'}
				>
					<DialogTitle>
					Ausnahme überschreiben?
					</DialogTitle>
					<DialogContent className={classes.dialogBox}>

						Für {WorkTimeExceptionModal.getMonthName(this.props.selectedMonth)} {this.props.selectedYear} existiert bereit eine Ausnahme. Soll diese überschrieben werden?

					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.closeConfirmationDialog.bind(this)}
							variant="outlined"
							color="secondary"
						>
						Abbrechen
						</Button>
						<Button
							onClick={this.handleOverrideConfirm.bind(this)}
							variant="contained"
							color="secondary"
						>
						Überschreiben
						</Button>
					</DialogActions>
				</Dialog>
			</Dialog>
		);
	}

	private static getMonthName(selectedMonth: number): string {
		const months: string[] = [
			"Kein",
			"Jänner",
			"Februar",
			"März",
			"April",
			"Mai",
			"Juni",
			"Juli",
			"August",
			"September",
			"Oktober",
			"November",
			"Dezember"
		];

		return months[selectedMonth];
	}

	private createDaysHoursDataStruct(): void {

		let existingExceptions: {date: Date; hours: number}[] | null = [];
		if (this.props.workTimeException) {
			existingExceptions = this.state.workTimeExceptionToEdit;
		}
		//create remaining days
		const daysHours: {date: Date; hours: number | null}[] = [];

		const curMonthDate = new Date(this.props.selectedYear,this.props.selectedMonth-1,1);
		const curMonth = moment(curMonthDate).startOf('day');
		let dayOfMonth = 0;

		while(dayOfMonth < moment(curMonth).daysInMonth()){
			daysHours.push({ date: moment(curMonth).add(dayOfMonth, 'days').toDate(), hours: null });
			dayOfMonth++;
		}

		//merge existing with new exceptions
		existingExceptions.forEach((existingException: {date: Date; hours: number | null}): void => {
			const changeIndex = daysHours.findIndex((localException: {date: Date; hours: number | null}): boolean =>
				moment(localException.date).isSame(existingException.date,'day'));

			if(changeIndex >= 0){
				daysHours[changeIndex].hours = existingException.hours;
			}
		});

		this.setState({daysHours: daysHours});

	}

	private tableRows(): ReactNode[]{
		const nodes: ReactNode[] = [];

		moment.locale('de-DE');

		this.state.daysHours.forEach((row, idx): void => {
			if(moment(this.state.daysHours[idx].date).weekday()===0 || idx === 0){
				nodes.push(
					<TableRow
						key={"_"+idx}
						className={this.props.classes.weekDivider}
					>
						<TableCell scope="row" className={this.props.classes.weekDivider}>
						KW: {moment(this.state.daysHours[idx].date).format('WW')}
						</TableCell>
						<TableCell />
					</TableRow>
				);
			}

			nodes.push(
				<TableRow
					key={idx}
				>
					<TableCell scope="row">
						{
							moment(row.date).format('dddd DD MMMM YYYY')
						}
					</TableCell>
					<TableCell>
						<TextField
							style={{width: '50px'}}
							value={row.hours ? row.hours : undefined}
							placeholder={this.getStandardWorktime(row.date)}
							onChange={(e): void =>this.changeHour(e,idx)} type={"number"}
						/>
					</TableCell>
				</TableRow>
			);
		});


		return nodes;
	}

	private printDayTable(): ReactNode {
		//build table strucutre
		moment.locale('de');

		return(
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Tag</TableCell>
						<TableCell>Stunden</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{this.tableRows()}
				</TableBody>
			</Table>
		);
	}

	private changeHour(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, idx: number): void {
		const daysHours = [...this.state.daysHours];
		daysHours[idx].hours = parseFloat(e.target.value);
		this.setState({daysHours: daysHours});
	}


	private getStandardWorktime(date: Date): string | undefined {
		if (this.props.selectedUser.workingWeekdays) {
			const currentWorkWeekdays = this.props.selectedUser.workingWeekdays;
			let workingHours = [0,0,0,0,0];
			for (const currentWorkWeekday of currentWorkWeekdays) {
				if (new Date(date) >= new Date(currentWorkWeekday.startDate) && new Date(date) <= new Date(currentWorkWeekday.endDate)) {
					workingHours = currentWorkWeekday.weekdays;
				}
			}
			const workingHour = workingHours[moment(date).weekday()];
			if(workingHour !== undefined){
				return String(workingHour);
			}
		}
		return undefined;
	}

	private exceptionsExist(): boolean {
		if (this.props.selectedUser.worktimeExceptions) {
			const currentWorkTimeExceptions = this.props.selectedUser.worktimeExceptions;
			for (const currentWorkTimeException of currentWorkTimeExceptions) {
				if (new Date(currentWorkTimeException.date).getFullYear() === this.props.selectedYear && new Date(currentWorkTimeException.date).getMonth() + 1 === this.props.selectedMonth) {
					return true;
				}
			}
		}

		return false;
	}


}

export default withStyles(worktimeExceptionStyles)(WorkTimeExceptionModal);
