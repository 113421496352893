import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';


const vacationStyles = (theme: Theme): StyleRules<string> =>
	createStyles({
		fabPrint:{
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(2)
		},
		vacationEmployeesButton: {
			padding: "10px",
			margin: "10px",
			backgroundColor: "secondary"
		},
		headerContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-start'
		}
	});

export default vacationStyles;
