import React, { Component, ReactNode, ChangeEvent } from 'react';

import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	withStyles,
	WithStyles
} from '@material-ui/core';

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import { WorkingWeekdays } from './../UserData.Model';
import workdaysStyles from './WorkdaysStyles';

interface State {
	workingWeekdaysToEdit: WorkingWeekdays;
	inputMissing: boolean;
}

interface Props extends WithStyles<typeof workdaysStyles> {
	workingWeekdays: WorkingWeekdays | null;
	closeHandler: Function;
	dialogOpen: boolean;
	handleWorkingWeekdays: Function;
	index: number;
}

class WorkdaysModal extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		this.state = {
			workingWeekdaysToEdit: this.getWorkingWeekdaysToSet(props.workingWeekdays),
			inputMissing: false
		};
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (this.props.workingWeekdays !== prevProps.workingWeekdays) {
			this.setState({workingWeekdaysToEdit: this.getWorkingWeekdaysToSet(this.props.workingWeekdays)});
		}
	}

	private getWorkingWeekdaysToSet(newWorkingWeekdays: WorkingWeekdays | null): WorkingWeekdays {
		if (newWorkingWeekdays) {
			return newWorkingWeekdays;
		} else {
			const workingWeekdays: WorkingWeekdays = {
				weekdays: [],
				startDate: new Date(new Date().toDateString()), // Small hack so that default value is set
				endDate: new Date(new Date().toDateString()), // Small hack so that default value is set
				vacationDays: 25,
				initVacationDays: 0,
				initOvertime: 0,
				initVacationDaysYear: 0
			};
			return workingWeekdays;
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog
				open={this.props.dialogOpen}
				maxWidth={'lg'}>
				<DialogTitle>
					{this.props.workingWeekdays ? 'Arbeitszeit bearbeiten' : 'Neue Arbeitszeit hinzufügen'}
				</DialogTitle>
				<DialogContent className={classes.container}>
					<div className={classes.dates}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="dd. MM. yyyy"
								margin="normal"
								id="date-picker-startDate"
								label="Startdatum"
								value={this.state.workingWeekdaysToEdit.startDate}
								onChange={(date): void => this.changeDate(date, 'start')}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className={classes.startDate}
								autoOk={true}
							/>

							<KeyboardDatePicker
								disableToolbar
								variant="inline"
								format="dd. MM. yyyy"
								margin="normal"
								id="date-picker-endDate"
								label="Enddatum"
								value={this.state.workingWeekdaysToEdit.endDate}
								onChange={(date): void => this.changeDate(date, 'end')}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								autoOk={true}
							/>
						</MuiPickersUtilsProvider>
					</div>
					<div className={classes.weekdaysContainer}>
						<div className={classes.weekdayInput}>
							<TextField
								type={"number"}
								value={this.state.workingWeekdaysToEdit.weekdays[0] ?? ''}
								label={"Montag"}
								onChange={this.changeWeekdayHour.bind(this, 0)}
								error={this.state.inputMissing}
							/>
						</div>
						<div className={classes.weekdayInput}>
							<TextField
								type={"number"}
								value={this.state.workingWeekdaysToEdit.weekdays[1] ?? ''}
								label={"Dienstag"}
								onChange={this.changeWeekdayHour.bind(this, 1)}
								error={this.state.inputMissing}
							/>
						</div>
						<div className={classes.weekdayInput}>
							<TextField
								type={"number"}
								value={this.state.workingWeekdaysToEdit.weekdays[2] ?? ''}
								label={"Mittwoch"}
								onChange={this.changeWeekdayHour.bind(this, 2)}
								error={this.state.inputMissing}
							/>
						</div>
						<div className={classes.weekdayInput}>
							<TextField
								type={"number"}
								value={this.state.workingWeekdaysToEdit.weekdays[3] ?? ''}
								label={"Donnerstag"}
								onChange={this.changeWeekdayHour.bind(this, 3)}
								error={this.state.inputMissing}
							/>
						</div>
						<div className={classes.weekdayInput}>
							<TextField
								type={"number"}
								value={this.state.workingWeekdaysToEdit.weekdays[4] ?? ''}
								label={"Freitag"}
								onChange={this.changeWeekdayHour.bind(this, 4)}
								error={this.state.inputMissing}
							/>
						</div>
						<div className={classes.weekdayInput}>
							<TextField
								value={this.weekdaySum()}
								label={"Wochensumme"}
								disabled={true}
							/>
						</div>

					</div>
					<div className={classes.bottomRow}>
						<div className={classes.initValues}>
							<TextField
								value={this.state.workingWeekdaysToEdit.initVacationDaysYear}
								type={"number"}
								defaultValue={0}
								label={"Anfangsstand Jahresurlaub"}
								onChange={this.changeInput.bind(this, 'initVacationYear')}
								fullWidth={true}
							/>
						</div>
						<div className={classes.initValues}>
							<TextField
								value={this.state.workingWeekdaysToEdit.initVacationDays}
								type={"number"}
								label={"Anfangsstand Urlaubstage Aliquot"}
								onChange={this.changeInput.bind(this, 'initVacation')}
								fullWidth={true}
							/>
						</div>
						<div className={classes.initValues}>
							<TextField
								value={this.state.workingWeekdaysToEdit.initOvertime}
								type={"number"}
								label={"Anfangsstand Überstunden"}
								onChange={this.changeInput.bind(this, 'initOvertime')}
								fullWidth={true}
							/>
						</div>
					</div>
					<div className={classes.bottomRow}>
						<div className={classes.vacationDays}>
							<TextField
								value={this.state.workingWeekdaysToEdit.vacationDays}
								type={"number"}
								defaultValue={25}
								label={"Urlaubstage/Jahr"}
								onChange={this.changeInput.bind(this, 'monthVacation')}
							/>
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="secondary"
						onClick={this.closeDialog.bind(this)}
					>
							Abbrechen
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={this.saveWorkdays.bind(this)}
					>
							Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	private saveWorkdays(): void {
		if (this.state.workingWeekdaysToEdit.weekdays.length === 5 && !isNaN(this.state.workingWeekdaysToEdit.weekdays[0])  && !isNaN(this.state.workingWeekdaysToEdit.weekdays[1]) && !isNaN(this.state.workingWeekdaysToEdit.weekdays[2]) && !isNaN(this.state.workingWeekdaysToEdit.weekdays[3]) && !isNaN(this.state.workingWeekdaysToEdit.weekdays[4])) {
			let isNew = true;
			if (this.props.workingWeekdays) {
				isNew = false;
			}
			this.props.handleWorkingWeekdays(this.state.workingWeekdaysToEdit, isNew, this.props.index);
			this.setState({inputMissing: false});
			this.closeDialog();
		} else {
			this.setState({inputMissing: true});
		}
	}

	private closeDialog(): void {
		if (this.props.workingWeekdays === null) {
			this.setState({workingWeekdaysToEdit: this.getWorkingWeekdaysToSet(null)});
		}
		this.props.closeHandler();
	}

	private changeWeekdayHour(weekday: number, e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
		const currentWorkingWeekdays = this.state.workingWeekdaysToEdit;
		if (isNaN(parseFloat(e.target.value))) {
			currentWorkingWeekdays.weekdays[weekday] = 0;
		} else {
			currentWorkingWeekdays.weekdays[weekday] = parseFloat(e.target.value);
		}

		this.setState({workingWeekdaysToEdit: currentWorkingWeekdays });
	}

	private changeInput( type: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
		const currentWorkingWeekdays = this.state.workingWeekdaysToEdit;
		if (isNaN(parseFloat(e.target.value))) {
			if (type === 'initOvertime') {
				currentWorkingWeekdays.initOvertime = 0;
			} else if (type === 'initVacation') {
				currentWorkingWeekdays.initVacationDays = 0;
			} else if (type === 'initVacationYear') {
				currentWorkingWeekdays.initVacationDaysYear = 0;
			} else {
				currentWorkingWeekdays.vacationDays = 0;
			}
		} else {
			if (type === 'initOvertime') {
				currentWorkingWeekdays.initOvertime = parseFloat(e.target.value);
			} else if (type === 'initVacation') {
				currentWorkingWeekdays.initVacationDays = parseFloat(e.target.value);
			} else if (type === 'initVacationYear') {
				currentWorkingWeekdays.initVacationDaysYear = parseFloat(e.target.value);
			} else {
				currentWorkingWeekdays.vacationDays = parseFloat(e.target.value);
			}
		}

		this.setState({workingWeekdaysToEdit: currentWorkingWeekdays });
	}

	private weekdaySum(): number {
		let sum = 0;

		if (this.state.workingWeekdaysToEdit.weekdays) {
			this.state.workingWeekdaysToEdit.weekdays.forEach((value: number): void => {
				sum += value;
			});
		}

		return sum;
	}

	private changeDate(date: MaterialUiPickersDate, type: string): void {
		if (date) {
			const currentWorkingWeekdays = this.state.workingWeekdaysToEdit;
			if (type === 'start') {
				currentWorkingWeekdays.startDate = new Date(date.toDateString());
			} else {
				currentWorkingWeekdays.endDate = new Date(date.toDateString());
			}
			this.setState({workingWeekdaysToEdit: currentWorkingWeekdays});
		}
	}

}

export default withStyles(workdaysStyles)(WorkdaysModal);
