import React, { Component, ReactNode } from 'react';

import AuthService from '../Common/AuthService';
import {
	CircularProgress,
	Fab,
	Paper,
	Snackbar,
	SnackbarContent,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

// TODO: remove usage of AppStyles, create own file if necessary
import trackingStyles from './TrackingStyles';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';

import TimeTrackingService, { LoxoneRESTLog } from '../Common/TimeTrackingService';
import TimeTrackingMonth from './DataModels/TimeTrackingMonth';
import TimeTrackingDay from './DataModels/TimeTrackingDay';
import { UserDataModel } from '../Admin/UserData.Model';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment, { Moment } from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import TimeTrackingTableRowComponent from './TableRow/TrackingTableRow';
import TrackingSummaryComponent from './Summary/TrackingSummary';
import TrackingPrinter from './Printer/TrackingPrinter';
import LoxoneTimesModal from './LoxoneTimesModal/LoxoneTimesModal';
import Dialog from '@material-ui/core/Dialog';
import TrackingTableFooter from './TableFooter/TrackingTableFooter';

interface State {
	menuAnchor: Element | null;
	selectedMonth: Moment;
	currentMonthData?: TimeTrackingMonth;
	previousMonthData?: TimeTrackingMonth;
	selectedUser?: UserDataModel;
	showLoxoneDialog: boolean;
	loxoneLogs: LoxoneRESTLog[];
	snackbarVisible: boolean;
	snackbarMessage: string;
	snackBar?: ReactNode;
	timeTrackingMonths: TimeTrackingMonth[];
	futureEntryDate: Date | null;
}

class Tracking extends Component<WithStyles<typeof trackingStyles>, State> {
	private authService: AuthService;
	private timeTrackingService: TimeTrackingService;
	public daysToImport?: TimeTrackingDay[];


	public constructor(props: WithStyles<typeof trackingStyles>) {
		super(props);
		this.authService = new AuthService();
		this.timeTrackingService = new TimeTrackingService();
		this.daysToImport = [];

		const selectedMonth = moment();

		this.state = {
			menuAnchor: null,
			selectedMonth: selectedMonth,
			showLoxoneDialog: false,
			loxoneLogs: [],
			snackbarVisible: false,
			snackbarMessage: "Keine neuen Nachrichten",
			timeTrackingMonths: [],
			futureEntryDate: null
		};
	}

	public componentWillMount(): void {
		this.loadTimeTrackingData(this.state.selectedMonth);
		this.loadUserdata();
		this.loadAllTimeTrackingMonths();
	}

	private async loadAllTimeTrackingMonths(): Promise<void> {
		const timeTrackingMonths = await this.timeTrackingService.getAllTrackedTimes();
		this.setState({timeTrackingMonths: timeTrackingMonths});
	}

	private async loadUserdata(): Promise<void> {
		const me = await this.authService.me();
		this.setState({selectedUser: me.data});
	}

	private async loadTimeTrackingData(month: Moment): Promise<void> {
		const currentMonth = await this.timeTrackingService.getTrackedTimes(month);
		const previousMonth = await this.timeTrackingService.getTrackedTimes(month.clone().subtract(1,"month"));

		this.setState({currentMonthData: currentMonth, previousMonthData: previousMonth});
	}

	public showLoxoneDialog(day: TimeTrackingDay, data: LoxoneRESTLog[]): void {
		this.setState({loxoneLogs: data, showLoxoneDialog: true});
	}

	public handleRowChange(data: TimeTrackingDay): void {
		if (this.state.currentMonthData === undefined) {
			return;
		}

		const currentData = this.state.currentMonthData;
		currentData.days[data.date.date() - 1] = data;
		this.setState({currentMonthData: currentData});
	}

	public getDaysToImport(): void {
		if(this.state.currentMonthData !== undefined && this.state.currentMonthData.days !== undefined) {
			this.daysToImport = [];
			for(const day of this.state.currentMonthData.days) {
				this.daysToImport.push(day);
			}
		}
	}

	async triggerMonthOfLoxoneImport(): Promise<void> {
		if(this.daysToImport !== undefined && this.daysToImport.length >= 0) {
			const day = this.daysToImport.splice(0,1)[0];
			if (day !== undefined) {
				const loxoneLogs = await this.timeTrackingService.getLoxoneData(day);
				if (loxoneLogs.length > 2) {
					this.showLoxoneDialog(day, loxoneLogs);
					return;
				} else {
					this.getLogsWithoutLoxoneDialog(day, loxoneLogs);
				}
				this.triggerMonthOfLoxoneImport();
			}
		}
	}

	public triggerDayOfLoxoneImport(day: TimeTrackingDay): void {
		this.timeTrackingService.getLoxoneData(day).then((loxoneLogs: LoxoneRESTLog[]): void => {
			if(loxoneLogs.length > 2) {
				this.showLoxoneDialog(day, loxoneLogs);
			} else {
				this.getLogsWithoutLoxoneDialog(day, loxoneLogs);
			}
		});
	}

	public getLogsWithoutLoxoneDialog(day: TimeTrackingDay, loxoneLogs: LoxoneRESTLog[]): void {
		const newTrackingDay = new TimeTrackingDay(day);
		if (loxoneLogs.length === 1) {
			newTrackingDay.fromAM = moment(loxoneLogs[0].time);
		}
		else if (loxoneLogs.length === 2) {
			newTrackingDay.fromAM = moment(loxoneLogs[0].time);
			newTrackingDay.untilPM = moment(loxoneLogs[1].time);
		}
		this.handleRowChange(newTrackingDay);
	}

	public render(): ReactNode {
		const classes = this.props.classes;


		// TODO: display loading animation
		if (
			!this.state.selectedUser ||
			!this.state.currentMonthData ||
			!this.state.previousMonthData
		) {
			return (<div className={classes.loadingWrapper}>
				<CircularProgress color={"primary"} />
			</div>);
		}

		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<TrackingPrinter
					currentMonthData={this.state.currentMonthData}
					previousMonthData={this.state.previousMonthData}
					user={this.state.selectedUser}
					timeTrackingMonths={this.state.timeTrackingMonths}
				/>
				<Paper className={"bc-unprintable"}>
					<IconButton
						style={{display: "inline-block"}}
						onClick={this.previousMonth.bind(this)}
					>
						<ChevronLeftIcon />
					</IconButton>
					<Typography style={{padding: "10px", fontWeight: "bold", display: "inline-block"}} >
						{this.state.selectedMonth.format("MMMM YYYY")}
					</Typography>
					<IconButton
						onClick={this.nextMonth.bind(this)}
					>
						<ChevronRightIcon />
					</IconButton>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell className={classes.tableHeader}>Tag</TableCell>
								<TableCell className={classes.tableHeader}>Von</TableCell>
								<TableCell className={classes.tableHeader}>Bis</TableCell>
								<TableCell className={classes.tableHeader}>Von</TableCell>
								<TableCell className={classes.tableHeader}>Bis</TableCell>
								<TableCell className={classes.tableHeader}>Soll</TableCell>
								<TableCell className={classes.tableHeader}>Ist</TableCell>
								<TableCell className={classes.tableHeader}>Differenz</TableCell>
								<TableCell colSpan={2} className={classes.tableHeader}>Bemerkung</TableCell>
								<TableCell className={classes.tableHeader}>Optionen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.currentMonthData.days.map((day, index): ReactNode => (
								<TimeTrackingTableRowComponent
									currentDay={day}
									previousDay={this.getPreviousDay(index)}
									key={index}
									changeHandler={this.handleRowChange.bind(this)}
									showSnackbar={this.successSnackbarWrapper.bind(this)}
									trackingLoxoneImport={this.triggerDayOfLoxoneImport.bind(this)}
								/>
							))}
						</TableBody>
						<TrackingTableFooter
							actualSum={this.state.currentMonthData.totalHoursThisMonth}
							requiredSum={this.state.currentMonthData.requiredSum}
						/>
					</Table>
				</Paper>
				<TrackingSummaryComponent
					currentMonthData={this.state.currentMonthData}
					previousMonthData={this.state.previousMonthData}
					user={this.state.selectedUser}
					timeTrackingMonths={this.state.timeTrackingMonths}
				/>
				<Fab
					color="secondary"
					aria-label="add"
					className={this.props.classes.fab}
					onClick={async (): Promise<void> => {
						if (this.state.currentMonthData !== undefined) {
							await this.timeTrackingService.saveTrackedTimes(this.state.currentMonthData);
							this.setState({snackbarVisible: true, snackbarMessage: "Speichern fertiggestellt"});
						}
					}}
				>
					<SaveIcon />
				</Fab>
				<Fab
					color="secondary"
					aria-label="add"
					className={this.props.classes.fabPrint}
					onClick={async (): Promise<void> => {
						window.print();
					}}
				>
					<PrintIcon />
				</Fab>
				<Fab
					color="secondary"
					aria-label="add"
					className={this.props.classes.fabMonth}
					onClick={async (): Promise<void> => {
						if(this.state.currentMonthData !== undefined && this.state.currentMonthData.days !== undefined) {
							this.getDaysToImport();
							this.triggerMonthOfLoxoneImport();
						}
					}}
				>
					<DateRangeIcon />
				</Fab>
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={this.state.snackbarVisible}
					autoHideDuration={3000}
					onClose={(): void => {
						this.setState({snackbarVisible: false});
					}}
				>
					<SnackbarContent
						aria-describedby="client-snackbar"
						message={
							<span>
								{this.state.snackbarMessage}
							</span>
						}
						action={[
							<IconButton key="close" color="inherit" onClick={(): void => {
								this.setState({snackbarVisible: false});
							}}>
								<CloseIcon  />
							</IconButton>,
						]}
					/>
				</Snackbar>
				<Dialog open={this.state.showLoxoneDialog}>
					<LoxoneTimesModal
						closeHandler={this.closeLoxoneDialog.bind(this)}
						loxoneLogs={this.state.loxoneLogs}
					/>
				</Dialog>
			</MuiPickersUtilsProvider>
		);
	}

	private successSnackbarWrapper(message: string): void {
		this.setState({snackbarMessage: message, snackbarVisible: true});
	}

	public closeLoxoneDialog(startTime: Moment | null, endTime: Moment | null): void {
		if (startTime && endTime && this.state.currentMonthData !== undefined) {
			const newDay = new TimeTrackingDay(this.state.currentMonthData.days[startTime.date()-1]);
			newDay.fromAM = startTime;
			newDay.untilPM = endTime;
			this.handleRowChange(newDay);
		}
		this.setState({showLoxoneDialog: false});
		this.triggerMonthOfLoxoneImport();
	}

	// TODO: maybe create a new Month Picker component that handles the month selection
	private nextMonth(): void {
		const newMonth = this.state.selectedMonth.clone().add(1, 'months');
		this.setState({selectedMonth: newMonth}, () => {
			this.loadTimeTrackingData(newMonth);
		});
	}

	private previousMonth(): void {
		const newMonth = this.state.selectedMonth.clone().subtract(1, 'months');
		this.setState({selectedMonth: newMonth}, () => {
			this.loadTimeTrackingData(newMonth);
		});
	}

	private getPreviousDay(index: number): TimeTrackingDay | undefined {
		if(index === 0 && this.state.previousMonthData){
			return this.state.previousMonthData.days[this.state.previousMonthData.days.length-1];
		}else if(index > 0 && this.state.currentMonthData){
			return this.state.currentMonthData.days[index-1];
		}
	}
}

export default withStyles(trackingStyles)(Tracking);
