import React, { Component, ReactNode } from 'react';

import {
	ListItem,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { UserDataModel } from '../Admin/UserData.Model';
import TimeTrackingMonth from '../Tracking/DataModels/TimeTrackingMonth';
import TimeTrackingDay from '../Tracking/DataModels/TimeTrackingDay';
import vacationMonthStyles from './VacationMonthStyles';

interface Props extends WithStyles<typeof vacationMonthStyles> {
	timeTrackingMonth: TimeTrackingMonth;
	selectedUser: UserDataModel | null;
	timeTrackingMonths: TimeTrackingMonth[];
}

class VacationMonth extends Component<Props> {
	private months = ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

	public constructor(props: Props) {
		super(props);
	}

	private getMonthName(month: number): string {
		return this.months[month];
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return(
			<ListItem className={classes.listItem}>
				<ListItemText>
					<Typography variant="h4">{this.getMonthName(this.props.timeTrackingMonth.month) + ' ' + this.props.timeTrackingMonth.year}</Typography>
					<Typography className={classes.vacationType} variant="h6">Urlaub-Konto (Aliquot):</Typography>
					<div className={classes.monthValues}>
						<div>Urlaub Monatsbeginn:<b> {(this.props.timeTrackingMonth.vacationBalance - this.props.timeTrackingMonth.getVacationDaysThisMonth(this.props.selectedUser?.workingWeekdays) - this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.selectedUser?.specialVacations) + this.props.timeTrackingMonth.getConsumedVacationDaysThisMonth()).toFixed(2)}</b> Tage</div>
						<div>Urlaub erhalten:<b> {this.props.timeTrackingMonth.getVacationDaysThisMonth(this.props.selectedUser?.workingWeekdays).toFixed(2)}</b> Tage</div>
						<div>Sonderurlaub erhalten:<b> {this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.selectedUser?.specialVacations)}</b> Tage</div>
						<div>Urlaub verbraucht:<b> {this.props.timeTrackingMonth.getConsumedVacationDaysThisMonth()}</b> Tage</div>
						<div>Urlaub Monatsende:<b> {this.props.timeTrackingMonth.vacationBalance.toFixed(2)}</b> Tage</div>
					</div>
					<Typography className={classes.vacationType} variant="h6">Jahresurlaub:</Typography>
					<div className={classes.monthValues}>
						<div>Jahresurlaub Monatsbeginn:<b> {(this.props.timeTrackingMonth.vacationYearBalance + Math.abs(this.props.timeTrackingMonth.getConsumedVacationDaysThisMonth() + this.props.timeTrackingMonth.getVacationYearDaysToAdd(this.props.selectedUser?.workingWeekdays, this.props.selectedUser?.entryDate, this.props.timeTrackingMonths, 'subtract')) - this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.selectedUser?.specialVacations) - (this.props.timeTrackingMonth.getYearlyAddedVacationYearDays(this.props.selectedUser?.workingWeekdays, this.props.selectedUser?.entryDate) + this.props.timeTrackingMonth.getVacationYearDaysToAdd(this.props.selectedUser?.workingWeekdays, this.props.selectedUser?.entryDate, this.props.timeTrackingMonths, 'add') + this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.selectedUser?.specialVacations)))}</b> Tage</div>
						<div>Jahresurlaub erhalten:<b> {(this.props.timeTrackingMonth.getYearlyAddedVacationYearDays(this.props.selectedUser?.workingWeekdays, this.props.selectedUser?.entryDate) + this.props.timeTrackingMonth.getVacationYearDaysToAdd(this.props.selectedUser?.workingWeekdays, this.props.selectedUser?.entryDate, this.props.timeTrackingMonths, 'add') + this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.selectedUser?.specialVacations))}</b> Tage</div>
						<div>Sonderurlaub erhalten:<b> {this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.selectedUser?.specialVacations)}</b> Tage</div>
						<div>Jahresurlaub verbraucht:<b> {Math.abs(this.props.timeTrackingMonth.getConsumedVacationDaysThisMonth() + this.props.timeTrackingMonth.getVacationYearDaysToAdd(this.props.selectedUser?.workingWeekdays, this.props.selectedUser?.entryDate, this.props.timeTrackingMonths, 'subtract'))}</b> Tage</div>
						<div>Jahresurlaub Monatsende:<b> {this.props.timeTrackingMonth.vacationYearBalance}</b> Tage</div>
					</div>
					<div>
						<Table>
							{this.props.timeTrackingMonth.days.filter((day: TimeTrackingDay) => day.usesZa === 1).map((day: TimeTrackingDay): ReactNode => (
								<TableBody>
									<TableRow>
										<TableCell>{day.date.format('DD. MM. YYYY')}</TableCell>
										<TableCell align="right">{'Urlaub'}</TableCell>
									</TableRow>
								</TableBody>
							))}
						</Table>
					</div>
				</ListItemText>
			</ListItem>
		);
	}

}

export default withStyles(vacationMonthStyles)(VacationMonth);
