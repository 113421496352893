import React, { Component, ReactNode } from 'react';

import { WithStyles, withStyles } from '@material-ui/core';


import Utilities from '../../../Utilities';
import TimeTrackingDay from '../../DataModels/TimeTrackingDay';
import tableRowPrinterStyles from './TableRowPrinterStyles';

interface Props extends WithStyles<typeof tableRowPrinterStyles> {
	// TODO: rename to dayData or similiar
	dayData: TimeTrackingDay;
	previousDayData?: TimeTrackingDay;
	// TODO: we also need the previous days data to check if the current day is law compliant
}

// TODO: rename component (all components are Tracking* why is this one TimeTracking*)
class TableRowPrinter extends Component<Props> {

	public render(): ReactNode {
		const todaysData = this.props.dayData;

		const lawCompliance = todaysData.isLawCompliant(this.props.previousDayData);

		let commentString = todaysData.comment;

		if (todaysData.usesZa !== 0 && commentString.length === 0) {
			commentString = todaysData.usesZAString;
		} else if (todaysData.usesZa !== 0) {
			commentString = `${todaysData.usesZAString}; ${commentString}`;
		}

		// TODO: why do all tds have the same class (printedTableCell), you can just select all tds in css
		// Less code == better readability
		return (
			<tr className={
				`${ todaysData.isDayOff ? this.props.classes.dayOff : "" }
				 ${lawCompliance.length === 0 ? "" : this.props.classes.exceededWorkingHours }`
			}>
				<td>
					{todaysData.date.format("dd. DD.MM")}
				</td>
				<td >
					{todaysData.fromAM.format("HH:mm")}
				</td>
				<td >
					{todaysData.untilAM.format("HH:mm")}
				</td>
				<td >
					{todaysData.fromPM.format("HH:mm")}
				</td>
				<td >
					{todaysData.untilPM.format("HH:mm")}
				</td>
				<td >
					{Utilities.printHours(todaysData.requiredHours)}
				</td>
				<td >
					{Utilities.printHours(todaysData.actualHours)}
				</td>
				<td className={`${todaysData.differenceBetweenActualAndRequired < 0 ? this.props.classes.negativeHours : undefined}`}>
					{Utilities.printHours(todaysData.differenceBetweenActualAndRequired)}
				</td>
				<td >
					{commentString}
				</td>
			</tr>
		);
	}
}

export default withStyles(tableRowPrinterStyles)(TableRowPrinter);
