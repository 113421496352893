import React, { Component, ReactNode, ChangeEvent } from 'react';

import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	TextField,
	withStyles,
	WithStyles
} from '@material-ui/core';

import moment from 'moment';
import { UserDataModel, SpecialVacation } from './../UserData.Model';
import specialVacationStyles from './SpecialVacationStyles';
import TimeTrackingService from './../../Common/TimeTrackingService';

interface State {
	specialVacationToEdit: SpecialVacation;
	inputMissing: boolean;
	selectedMonth: number;
	selectedYear: number;
	selectableDates: {year: number; month: number}[];
	confirmationDialogOpen: boolean;
	selectableYears: number[];
	selectableMonthsByYear: number[];
}

interface Props extends WithStyles<typeof specialVacationStyles> {
	specialVacation: SpecialVacation | null;
	closeHandler: Function;
	dialogOpen: boolean;
	handleSpecialVacation: Function;
	selectedUser: UserDataModel;
	specialVacationExists: (newSpecialVacation: SpecialVacation) => boolean;
}

class SpecialVacationModal extends Component<Props, State> {
	private timeTrackingService: TimeTrackingService;

	public constructor(props: Props) {
		super(props);
		this.timeTrackingService = new TimeTrackingService();

		this.state = {
			specialVacationToEdit: this.getSpecialVacationToSet(props.specialVacation),
			inputMissing: false,
			selectedMonth: 0,
			selectedYear: moment().year(),
			selectableDates: [],
			confirmationDialogOpen: false,
			selectableYears: [],
			selectableMonthsByYear: []
		};
	}

	public async componentDidMount(): Promise<void> {
		await this.loadSelectableDates();
		this.getSelectableMonthsByYear(moment().year());
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (this.props.specialVacation !== prevProps.specialVacation) {
			this.setState({specialVacationToEdit: this.getSpecialVacationToSet(this.props.specialVacation)});
		}
	}

	private getSpecialVacationToSet(newSpecialVacation: SpecialVacation | null): SpecialVacation {
		if (newSpecialVacation) {
			this.setState({selectedMonth: new Date(newSpecialVacation.date).getMonth(), selectedYear: new Date(newSpecialVacation.date).getFullYear()});
			return newSpecialVacation;
		} else {
			const specialVacation: SpecialVacation = {
				date: new Date(moment().year(), 0, 1),
				days: 0
			};
			return specialVacation;
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog
				open={this.props.dialogOpen}
				maxWidth={'md'}>
				<DialogTitle>
					{this.props.specialVacation ? 'Sonderurlaub bearbeiten' : 'Neuen Sonderurlaub hinzufügen'}
				</DialogTitle>
				<DialogContent className={classes.container}>
					<FormControl
						style={{width: "150px", marginRight: "16px"}}
						variant='outlined'
						disabled={this.props.specialVacation ? true : false}
					>
						<InputLabel>Monat</InputLabel>
						<Select
							label={'Monat'}
							value={this.state.selectedMonth}
							onChange={(e,c): void => this.selectChanged(e, c, "month")}
						>
							{this.state.selectableMonthsByYear.map((month: number): ReactNode =>(
								this.getSelectableMonths(month)
							))}
						</Select>
					</FormControl>
					<FormControl
						style={{width: "150px"}}
						variant='outlined'
						disabled={this.props.specialVacation ? true : false}>
						<InputLabel>Jahr</InputLabel>
						<Select
							label={'Jahr'}
							value={this.state.selectedYear}
							onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>,c: ReactNode): void =>this.selectChanged(e,c,"year")}
						>
							{
								this.state.selectableYears.map((year: number): ReactNode =>(
									<MenuItem key={"year_"+year} value={year}>{year}</MenuItem>
								))
							}
						</Select>

					</FormControl>
					<div className={classes.vacationDays}>
						<TextField
							style={{width: "200px"}}
							value={this.state.specialVacationToEdit.days}
							type={"number"}
							label={"Anzahl Sonderurlaubstage"}
							onChange={this.changeSpecialVacationDays.bind(this)}
						/>
					</div>

				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="secondary"
						onClick={this.closeDialog.bind(this)}
					>
							Abbrechen
					</Button>
					<Button
						variant="contained"
						color="secondary"
						onClick={this.saveSpecialVacation.bind(this, false)}
					>
							Speichern
					</Button>
				</DialogActions>
				<Dialog
					open={this.state.confirmationDialogOpen}
					maxWidth={'xs'}
				>
					<DialogTitle>
					Sonderurlaub überschreiben?
					</DialogTitle>
					<DialogContent className={classes.dialogBox}>
						Für diesen Monat existiert bereits ein Sonderurlaub. Soll dieser überschrieben werden?
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.closeConfirmationDialog.bind(this)}
							variant="outlined"
							color="secondary"
						>
						Abbrechen
						</Button>
						<Button
							onClick={this.handleOverrideConfirm.bind(this)}
							variant="contained"
							color="secondary"
						>
						Überschreiben
						</Button>
					</DialogActions>
				</Dialog>
			</Dialog>
		);
	}

	private saveSpecialVacation(confirmed: boolean): void {
		if (!this.props.specialVacation && this.props.specialVacationExists(this.state.specialVacationToEdit) && !confirmed) {
			this.setState({confirmationDialogOpen: true});
		} else {
			this.props.handleSpecialVacation(this.state.specialVacationToEdit);
			this.closeDialog();
		}
	}

	private handleOverrideConfirm(): void {
		this.closeConfirmationDialog();
		this.saveSpecialVacation(true);
	}

	private closeDialog(): void {
		if (this.props.specialVacation === null) {
			this.setState({specialVacationToEdit: this.getSpecialVacationToSet(null)});
		}
		this.setState({selectedMonth: 0, selectedYear: moment().year()});
		this.getSelectableMonthsByYear(moment().year());
		this.props.closeHandler();
	}

	private closeConfirmationDialog(): void {
		this.setState({confirmationDialogOpen: false});
	}

	private changeSpecialVacationDays(e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
		const currentSpecialVacation = this.state.specialVacationToEdit;
		if (isNaN(parseFloat(e.target.value))) {
			currentSpecialVacation.days = 0;
		} else {
			currentSpecialVacation.days = parseFloat(e.target.value);
		}

		this.setState({specialVacationToEdit: currentSpecialVacation });
	}

	private async loadSelectableDates(): Promise<void> {
		const selectableDates: {year: number; month: number}[] = [];
		const years: number[] = [];
		const timeTrackingMonths = await this.timeTrackingService.getAllTrackedTimes();


		for (const timeTrackingMonth of timeTrackingMonths) {
			for (const day of timeTrackingMonth.days) {
				if (!day.fromAM.isSame(day.date) || !day.untilAM.isSame(day.date) || !day.fromPM.isSame(day.date) || !day.untilPM.isSame(day.date)) {
					selectableDates.push({year: timeTrackingMonth.year, month: timeTrackingMonth.month});
					if (!years.includes(timeTrackingMonth.year)) {
						years.push(timeTrackingMonth.year);
					}
					break;
				}
			}

		}
		this.setState({selectableDates: selectableDates, selectableYears: years});
	}

	private selectChanged(event: React.ChangeEvent<{ name?: string; value: unknown }>, _: ReactNode,selectionType: string): void {
		const currentSpecialVacation = this.state.specialVacationToEdit;
		if (selectionType === "month") {
			this.setState({selectedMonth: parseInt(event.target.value as string)});
			currentSpecialVacation.date.setMonth(parseInt(event.target.value as string));
		} else if(selectionType === "year") {
			this.setState({selectedYear: parseInt(event.target.value as string)});
			currentSpecialVacation.date.setFullYear(parseInt(event.target.value as string));
			const selectableMonths = this.getSelectableMonthsByYear(parseInt(event.target.value as string));
			currentSpecialVacation.date.setMonth(selectableMonths[0]);
		}
		this.setState({specialVacationToEdit: currentSpecialVacation});
	}

	private getSelectableMonthsByYear(year: number): number[] {
		const selectableMonths: number[] = [];
		for (const selectableDate of this.state.selectableDates) {
			if (selectableDate.year === year) {
				selectableMonths.push(selectableDate.month);
			}
		}
		this.setState({selectableMonthsByYear: selectableMonths, selectedMonth: selectableMonths[0]});
		return selectableMonths;
	}

	private getSelectableMonths(month: number): ReactNode {
		if (month === 0) {
			return <MenuItem key={"month_" + month} value={0}>Jänner</MenuItem>;
		} else if (month === 1) {
			return <MenuItem key={"month_" + month} value={1}>Februar</MenuItem>;
		} else if (month === 2) {
			return <MenuItem key={"month_" + month} value={2}>März</MenuItem>;
		} else if (month === 3) {
			return <MenuItem key={"month_" + month} value={3}>April</MenuItem>;
		} else if (month === 4) {
			return <MenuItem key={"month_" + month} value={4}>Mai</MenuItem>;
		} else if (month === 5) {
			return <MenuItem key={"month_" + month} value={5}>Juni</MenuItem>;
		} else if (month === 6) {
			return <MenuItem key={"month_" + month} value={6}>Juli</MenuItem>;
		} else if (month === 7) {
			return <MenuItem key={"month_" + month} value={7}>August</MenuItem>;
		} else if (month === 8) {
			return <MenuItem key={"month_" + month} value={8}>September</MenuItem>;
		} else if (month === 9) {
			return <MenuItem key={"month_" + month} value={9}>Oktober</MenuItem>;
		} else if (month === 10) {
			return <MenuItem key={"month_" + month} value={10}>November</MenuItem>;
		} else {
			return <MenuItem key={"month_" + month} value={11}>Dezember</MenuItem>;
		}
	}

}

export default withStyles(specialVacationStyles)(SpecialVacationModal);
