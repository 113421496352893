import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const tableRowPrinterStyles = (theme: Theme): StyleRules<string> => createStyles({
	dayOff:{
		backgroundColor: "#e0e0e0",
	},
	exceededWorkingHours: {
		backgroundColor: "#ff7980",
		borderColor: "#7d0000"
	},
	negativeHours: {
		color: "#F00"
	},
	commentCell: {
		width: "20%"
	}
});

export default tableRowPrinterStyles;
