import React, { Component, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';

import AuthService from '../Common/AuthService';
import {
	Button,
	Divider,
	Fab,
	List,
	Paper,
	WithStyles,
	withStyles
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

import VacationPrinter from './Printer/VacationPrinter';
import TimeTrackingMonth from '../Tracking/DataModels/TimeTrackingMonth';
import { UserDataModel } from '../Admin/UserData.Model';

import TimeTrackingService from '../Common/TimeTrackingService';

import VacationMonth from './VacationMonth';
import vacationStyles from './VacationStyles';

interface State {
	timeTrackingMonthsFiltered: TimeTrackingMonth[];
	selectedUser: UserDataModel | null;
	timeTrackingMonths: TimeTrackingMonth[];
}

interface Props extends WithStyles<typeof vacationStyles>, RouteComponentProps {
}

class Vacation extends Component<Props, State> {
	private timeTrackingService: TimeTrackingService;
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.timeTrackingService = new TimeTrackingService();

		this.state = {
			timeTrackingMonthsFiltered: [],
			selectedUser: null,
			timeTrackingMonths: []
		};
	}

	public componentDidMount(): void {
		this.loadTrackedTimes();
		this.loadUserdata();
	}

	private async loadUserdata(): Promise<void> {
		const me = await this.authService.me();
		this.setState({selectedUser: me.data});
	}

	private async loadTrackedTimes(): Promise<void> {
		const timeTrackingMonthsWorked: TimeTrackingMonth[] = [];
		const allTrackedTimes = await this.timeTrackingService.getAllTrackedTimes();
		this.setState({timeTrackingMonths: allTrackedTimes});
		for (const timeTrackingMonth of allTrackedTimes) {
			for (const day of timeTrackingMonth.days) {
				if (!day.fromAM.isSame(day.date) || !day.untilAM.isSame(day.date) || !day.fromPM.isSame(day.date) || !day.untilPM.isSame(day.date)) {
					timeTrackingMonthsWorked.push(timeTrackingMonth);
					break;
				}
			}
		}

		const timeTrackingMonthsWorkedSorted = timeTrackingMonthsWorked.sort(
			function(month1, month2) {
				if (month1.year > month2.year) {
					return 1;
				} else if (month1.year < month2.year) {
					return -1;
				} else {
					if (month1.month > month2.month) {
						return 1;
					} else if (month1.month < month2.month) {
						return -1;
					} else {
						return 0;
					}
				}
			}
		);

		this.setState({timeTrackingMonthsFiltered: timeTrackingMonthsWorkedSorted});
	}

	public render(): ReactNode {
		return(
			<>
				<VacationPrinter
					userData={this.state.selectedUser}
					timeTrackingMonthsFiltered={this.state.timeTrackingMonthsFiltered}
					timeTrackingMonths={this.state.timeTrackingMonths}
				/>
				<div className={"bc-unprintable"}>
					<div className={this.props.classes.headerContainer}>
						<h1>Eigene Urlaubsübersicht</h1>
						{this.authService.isAdmin() &&
							<Button
								variant="contained"
								color="secondary"
								className={this.props.classes.vacationEmployeesButton}
								onClick={(): void => {
									this.props.history.push('/vacation-employees');
								}}
							>
							Urlaubs- und Krankentage Übersicht
							</Button>
						}
					</div>
					<Paper>
						<List>
							{this.state.timeTrackingMonthsFiltered.map((timeTrackingMonth: TimeTrackingMonth): ReactNode => (
								<>
									<VacationMonth
										timeTrackingMonth={timeTrackingMonth}
										selectedUser={this.state.selectedUser}
										timeTrackingMonths={this.state.timeTrackingMonths}
									/>
									<Divider />
								</>
							))}
						</List>
					</Paper>
					<Fab
						color="secondary"
						className={this.props.classes.fabPrint}
						onClick={async (): Promise<void> => {
							window.print();
						}}
					>
						<PrintIcon />
					</Fab>
				</div>
			</>
		);
	}

}

export default withStyles(vacationStyles)(Vacation);
