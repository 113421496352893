import React, { Component, ReactNode } from 'react';

import AuthService from '../Common/AuthService';
import { Dialog, DialogTitle, DialogContent, DialogActions,Paper, WithStyles, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { UserDataModel } from './UserData.Model';
import Button from '@material-ui/core/Button';
import adminStyles from './AdminStyles';
import EditUserModal from './EditUser/EditUserModal';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


interface State {
	editorOpen: boolean;
	selectedUser: UserDataModel | null;
	loadedUsers: UserDataModel[];
	menuAnchor: Element | null;
	confirmationDialogOpen: boolean;
}

class Admin extends Component<WithStyles<typeof adminStyles>, State> {
	private authService: AuthService;

	public constructor(props: WithStyles<typeof adminStyles>) {
		super(props);

		this.authService = new AuthService();
		this.state = {
			editorOpen: false,
			selectedUser: null,
			loadedUsers: [],
			menuAnchor: null,
			confirmationDialogOpen: false
		};
	}

	public async componentDidMount(): Promise<void> {
		await this.loadUsers();
	}

	private async loadUsers(): Promise<void> {
		const users = await this.authService.getAllUsers();
		this.setState({loadedUsers: users});
	}

	private closeEditUserModal(): void {
		this.setState({ editorOpen: false });
		this.loadUsers();
	}

	private closeConfirmationDialog(): void {
		this.setState({confirmationDialogOpen: false});
	}

	private showEditUserModal(user: UserDataModel | null): void {
		if (user !== null) {
			this.authService.loadUser(user).then((data: UserDataModel): void => {
				// let loadedUser: UserDataModel = JSON.parse(data);
				this.setState({ selectedUser: data, editorOpen: true, menuAnchor: null });
			});
		}

		this.setState({ selectedUser: user, editorOpen: true, menuAnchor: null });
	}

	private deleteUser(user: UserDataModel | null): void {
		if (user === null) {
			return;
		}

		this.setState({ selectedUser: null, menuAnchor: null, confirmationDialogOpen: false });

		this.authService.deleteUser(user).then((): void => {
			this.loadUsers();
		});
	}

	private toggleMenu(user: UserDataModel, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		if (this.state.menuAnchor !== null) {
			this.setState({
				selectedUser: null,
				menuAnchor: null
			});
		} else {
			this.setState({
				selectedUser: user,
				menuAnchor: event.currentTarget as Element
			});
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div>
				<div className={this.props.classes.headerContainer}>
					<h1>Adminpanel</h1>
					<Button
						onClick={this.showEditUserModal.bind(this, null)}
						className={classes.newUserButton}
						variant="contained"
						color="secondary"
					>
						{'Neuen Benutzer anlegen'}
					</Button>
				</div>
				<Dialog
					open={this.state.editorOpen}
					fullWidth={true}
					maxWidth={false}
				>
					<EditUserModal
						selectedUser={this.state.selectedUser}
						closeHandler={this.closeEditUserModal.bind(this)}
					/>
				</Dialog>
				<Paper>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell align={'center'}>Administrator</TableCell>
								<TableCell>Optionen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.loadedUsers.map((user): ReactNode => (
								<TableRow
									key={user._id}
								>
									<TableCell component="th" scope="row">
										{`${user.firstName} ${user.lastName}`}
									</TableCell>
									<TableCell>
										{`${user.email}`}
									</TableCell>
									<TableCell align={'center'}>
										{user.isAdmin && <CheckCircleIcon color="secondary" />}
									</TableCell>
									<TableCell>
										<div>
											<IconButton
												onClick={this.toggleMenu.bind(this, user)}
											>
												<MoreVertIcon />
											</IconButton>

										</div>
									</TableCell>
								</TableRow>
							))}
							<Menu
								onClose={(): void => {
									this.setState({
										selectedUser: null,
										menuAnchor: null
									});
								}}
								anchorEl={this.state.menuAnchor}
								open={this.state.menuAnchor !== null}
								PaperProps={{
									style: {
										width: 200
									}
								}}
							>
								<MenuItem
									onClick={this.showEditUserModal.bind(this, this.state.selectedUser)}
								>
									{'Bearbeiten'}
								</MenuItem>
								<MenuItem
									onClick={(): void => {this.setState({confirmationDialogOpen: true});}}
								>
									{'Löschen'}
								</MenuItem>
							</Menu>
						</TableBody>
					</Table>
				</Paper>

				<Dialog
					open={this.state.confirmationDialogOpen}
					maxWidth={'xs'}
				>
					<DialogTitle>
					User {this.state.selectedUser?.firstName} {this.state.selectedUser?.lastName} löschen?
					</DialogTitle>
					<DialogContent className={classes.dialogBox}>
						Soll der User wirklich gelöscht werden?
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.closeConfirmationDialog.bind(this)}
							variant="outlined"
							color="secondary"
						>
						Abbrechen
						</Button>
						<Button
							onClick={this.deleteUser.bind(this, this.state.selectedUser)}
							variant="contained"
							color="secondary"
						>
						Löschen
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withStyles(adminStyles)(Admin);
