import React, { Component, ReactNode } from 'react';
import Button from "@material-ui/core/Button";
import { RouteComponentProps, Switch, Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { WithStyles, withStyles } from "@material-ui/core";

import Tracking from "../Tracking/Tracking";
import Admin from "../Admin/Admin";
import Vacation from "../Vacation/Vacation";
import VacationEmployees from "../Vacation/VacationEmployess";

import appStyles from "./AppStyles";
import AuthService from '../Common/AuthService';
import PrivateRoute from './PrivateRoute';
import PublicHolidays from '../PublicHolidays/PublicHolidays';
import LoginAsButton from '../Authentication/LoginAsButton';

interface Props extends WithStyles<typeof appStyles>, RouteComponentProps {}

class AppWrapper extends Component<Props> {

	private authService: AuthService;

	public constructor(props: Props){
		super(props);
		this.authService = new AuthService();
	}

	public showAdminButton(): ReactNode{

		const classes = this.props.classes;

		if(this.authService.isAdmin()){
			return(
				<div>
					<Button
						variant="contained"
						color="secondary"
						className={classes.headerButton}
						component={Link}
						to="/publicHolidays"
					>
						Feiertage
					</Button>
					<Button
						variant="contained"
						color="secondary"
						className={classes.headerButton}
						component={Link}
						to="/admin"
					>
						Benutzer
					</Button>
				</div>
			);
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={classes.root}>
				<AppBar  position="fixed" className={`${classes.appBar} bc-unprintable`}>
					<Toolbar>
						<Typography className={classes.headline} variant="h6">
							{"ELEPHANTS 5 Zeiterfassung"} ({this.authService.getUserEmail()})
						</Typography>
						<div>
							<Button
								variant="contained"
								color="secondary"
								className={classes.headerButton}
								component={Link}
								to="/tracking"
							>
							Erfassung
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className={classes.headerButton}
								component={Link}
								to="/vacation"
							>
							Urlaubsübersicht
							</Button>
						</div>
						{
							this.showAdminButton()
						}
						<LoginAsButton />
						<Button
							variant="contained"
							color="primary"
							className={classes.logout}
							component={Link}
							to="/logout"
						>
              Abmelden
						</Button>
					</Toolbar>
				</AppBar>

				<div className={classes.container}>
					<div className={classes.toolbar} />
					<Switch>
						<PrivateRoute mustBeAdmin={true} path="/admin" component={Admin} />
						<PrivateRoute mustBeAdmin={false} path="/tracking" component={Tracking} />
						<PrivateRoute mustBeAdmin={false} path="/vacation" component={Vacation} />
						<PrivateRoute mustBeAdmin={true} path="/vacation-employees" component={VacationEmployees} />
						<PrivateRoute mustBeAdmin={true} path={"/publicHolidays"} component={PublicHolidays} />
						<PrivateRoute mustBeAdmin={false} component={Tracking} path={"/"} /> /*change first component here*/
					</Switch>
				</div>
			</div>
		);
	}
}

export default withStyles(appStyles)(AppWrapper);
