import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const trackingSummaryStyles = (theme: Theme): StyleRules<string> => createStyles({
	root: {
		'& td': {
			borderCollapse: 'collapse',
			border: "1px solid black",
			borderSpacing: 0,
			textAlign: "end",
			paddingRight: "4px",
			maxWidth: "100px",
		},
		'& table': {
			borderCollapse: 'collapse',
			border: "1px solid black",
			borderSpacing: 0,
			margin: "auto",
			marginTop: "20px"
		}
	},summaryCardWrapper:{
		width: "fit-content",
		margin: "25px auto",
		justifyContent: "space-around",
		display: "flex"
	},
	borderCell: {
		borderRight: '1px solid rgba(224, 224, 224, 1)'
	}
});

export default trackingSummaryStyles;
