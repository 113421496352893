import React, { ReactNode, Component } from "react";


import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	TextField,
	Checkbox, FormControlLabel
} from '@material-ui/core';



import { WithStyles, withStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import editPublicHolidayStyles from './EditPublicHolidayStyles';


import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';

import PublicHolidayModel from '../PublicHoliday.Model';
import PublicHolidayService from '../../Common/PublicHolidayService';

interface State {
	selectedPublicHoliday: PublicHolidayModel;
	selectedMonth: number;
	selectedYear: number;
	isVisible: Function;
	dialogOpen: boolean;
	editWorkTimeExceptions: boolean;
	selectError: boolean;
	MonthsWithExceptions: ReactNode[];
	menuAnchor: Element | null;
}

interface Props extends WithStyles<typeof editPublicHolidayStyles> {
	selectedPublicHoliday: PublicHolidayModel;
	isVisible: Function;
	loadPublicHolidays: Function;
}

class EditPublicHolidayModal extends Component<Props, State> {
	private publicHolidayService: PublicHolidayService;

	public constructor(props: Props) {
		super(props);
		this.publicHolidayService = new PublicHolidayService();

		//caluclate total weeksum

		this.state = {
			selectedPublicHoliday: props.selectedPublicHoliday,
			isVisible: props.isVisible,
			dialogOpen: true,
			selectedMonth: 0,
			selectedYear: 0,
			editWorkTimeExceptions: false,
			selectError: false,
			MonthsWithExceptions: [],
			menuAnchor: null,
		};

	}

	public switchOpen(action = 2, additionalAction = false): void {
		if (this.state.dialogOpen) {
			if (action === 0) {
				//saving date
				this.publicHolidayService.savePublicHoliday(this.state.selectedPublicHoliday);
				this.props.loadPublicHolidays();
			}
		}
		this.props.isVisible();
	}

	private handleDateChange(date: MaterialUiPickersDate, value?: string | null | undefined): void {
		if(date){
			const pubHoliday: PublicHolidayModel = this.state.selectedPublicHoliday;
			pubHoliday.date = moment(date).toDate();

			this.setState({selectedPublicHoliday: pubHoliday});
		}
	}

	private handleNameChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void {
		const curState = this.state.selectedPublicHoliday;
		curState.name = event.target.value;

		this.setState({selectedPublicHoliday: curState});
	}

	private handleHalfDayChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void {
		const curState = this.state.selectedPublicHoliday;

		curState.halfDay = !curState.halfDay;

		this.setState({selectedPublicHoliday: curState});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog
				aria-labelledby="customized-dialog-title"
				open={this.state.dialogOpen}
				maxWidth={false}
			>
				<DialogTitle id="customized-dialog-title">
					{ moment(this.state.selectedPublicHoliday.date).format('dddd DD MMMM YYYY') } Feiertag bearbeiten
				</DialogTitle>
				<DialogContent className={classes.dialogBox} style={{width: "100%"}} >

					<MuiPickersUtilsProvider utils={MomentUtils}  >
						<div style={{width: "100%"}} ><KeyboardDatePicker
							variant="inline"
							format="dddd DD MMMM YYYY"
							margin="normal"
							id="date-picker-inline"
							label="Datum des Feiertages"
							value={this.state.selectedPublicHoliday.date}
							onChange={(d,v): void =>this.handleDateChange(d,v)}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							fullWidth={true}
						/></div>
						<div><TextField
							value={this.state.selectedPublicHoliday.name}
							onChange={(e): void =>{this.handleNameChange(e);}}
							fullWidth={true}
							label={"Name"}
						/></div>
						<div>
							<FormControlLabel control={
								<Checkbox
									checked={this.state.selectedPublicHoliday.halfDay}
									onChange={(e): void=>this.handleHalfDayChange(e)}
								/>} label="Halbtags"/>
						</div>
					</MuiPickersUtilsProvider>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={(): void =>this.switchOpen(2, false)}
						variant="outlined"
						color="secondary"
					>
						Abbrechen
					</Button>
					<Button
						onClick={(): void =>this.switchOpen(0, false)}
						variant="contained"
						color="secondary"
					>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(editPublicHolidayStyles)(EditPublicHolidayModal);
