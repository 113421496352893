import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const summaryPrinterStyles = (theme: Theme): StyleRules<string> => createStyles({
	summaryRoot: {
		'& table': {
			borderSpacing: 0,
			width: "80%",
			margin: "auto",
			marginTop: 30,
			textAlign: "end",
			borderCollapse: 'collapse',
			fontSize: "11px",
			'& tr': {
				'& td': {
					borderCollapse: 'collapse',
					border: "1px solid black",
					paddingRight: 3,
				},
				'& td:first-child':{
					fontWeight: "normal"
				}
			}
		},
	},
	consumedVacationTable: {
		'& table': {
			marginTop: '0px',
			width: "100%",
			height: "100%",
			align: 'center',
			'& tr': {
				'& td': {
					border: '1px solid white'
				}
			}
		}
	}
});

export default summaryPrinterStyles;
