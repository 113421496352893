import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';


const vacationEmployeeMonthPrinterStyles = (_: Theme): StyleRules<string> =>
	createStyles({
		tableMonth: {
			pageBreakInside: 'avoid'
		},
		month: {
			padding: '4px',
			marginBottom: '20px'
		},
		monthValues: {
			marginBottom: '8px'
		},
		vacationDays: {
			display: 'flex',
			alignContent: 'flex-start',
			columnGap: '8px'
		},
		dayDetail: {
			float: 'right'
		}
	});

export default vacationEmployeeMonthPrinterStyles;
