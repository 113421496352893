import React, { ChangeEvent, Component, ReactNode } from 'react';

import {
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Select,
	Button,
	Typography,
	Menu,
	MenuItem,
	IconButton,
	WithStyles,
	withStyles
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import moment from 'moment';

import AuthService from './../../Common/AuthService';
import { UserDataModel, WorkingWeekdays, SpecialVacation } from './../UserData.Model';
import WorkTimeExceptionModal from '../WorktimeException/workTimeExceptionModal';
import SpecialVacationModal from '../SpecialVacation/SpecialVacationModal';
import WorkdaysModal from './WorkdaysModal';

import editUserStyles from './EditUserStyles';

interface State {
	editUser: UserDataModel;
	selectedMonth: number;
	selectedYear: number;
	dialogOpen: boolean;
	workdaysDialogOpen: boolean;
	specialVacationDialogOpen: boolean;
	editWorkTimeExceptions: boolean;
	selectError: boolean;
	MonthsWithExceptions: ReactNode[];
	menuAnchor: Element | null;
	workingWeekdaysToEdit: WorkingWeekdays | null;
	idx: number;
	workTimeExceptionToEdit: {date: Date; hours: number}[] | null;
	specialVacationToEdit: SpecialVacation | null;
	isLoading: boolean;
}

interface Props extends WithStyles<typeof editUserStyles> {
	selectedUser: UserDataModel | null;
	closeHandler: Function;
}

class EditUserModal extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();

		let user = this.props.selectedUser;
		if (user === null) {
			user = {
				_id: '',
				firstName: '',
				lastName: '',
				password: '',
				email: '',
				loxoneId: '',
				rocketChat: '',
				isAdmin: false,
				workingWeekdays: [],
				worktimeExceptions: [],
				specialVacations: [],
				entryDate: new Date()
			};
		}

		this.state = {
			editUser: user,
			dialogOpen: true,
			workdaysDialogOpen: false,
			selectedMonth: moment().month()+1,
			selectedYear: moment().year(),
			editWorkTimeExceptions: false,
			selectError: false,
			MonthsWithExceptions: [],
			menuAnchor: null,
			workingWeekdaysToEdit: null,
			idx: -1,
			workTimeExceptionToEdit: null,
			specialVacationDialogOpen: false,
			specialVacationToEdit: null,
			isLoading: false
		};
	}

	public componentWillMount(): void {
		this.getMonthsWithExceptions();
	}

	private changeEntryDate(date: MaterialUiPickersDate): void {
		if (date) {
			const editUser = this.state.editUser;
			editUser.entryDate = new Date(date.toDateString());
			this.setState({editUser: editUser});
		}
	}

	public alterUserDetails(
		prop: string,
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	): void {
		const curState: UserDataModel = this.state.editUser;

		if (prop === "isAdmin") {
			curState[prop] = !curState[prop];
		} else {
			curState[prop] = e.target.value;
		}

		this.setState({ editUser: curState });
	}

	public buildInputElementsByKey(): ReactNode {
		const classes = this.props.classes;
		const options = ["Bearbeiten", "Löschen"];

		return (
			<div>
				<div>
					<div className={this.props.classes.inputGroup}>
						<div className={this.props.classes.dialogInput}>
							<TextField
								value={this.state.editUser.email}
								onChange={this.alterUserDetails.bind(this, "email")}
								label={"E-Mail Adresse"}
								fullWidth
							/>
						</div>
						<div className={this.props.classes.dialogInput}>
							<TextField
								value={this.state.editUser.password}
								onChange={this.alterUserDetails.bind(this, "password")}
								type={"password"}
								label={"Passwort"}
								fullWidth
							/>
						</div>
					</div>
					<div className={this.props.classes.inputGroup}>
						<div className={this.props.classes.dialogInput}>
							<TextField
								value={this.state.editUser.firstName}
								onChange={this.alterUserDetails.bind(this, "firstName")}
								label={"Vorname"}
								fullWidth
							/>
						</div>
						<div className={this.props.classes.dialogInput}>
							<TextField
								value={this.state.editUser.lastName}
								onChange={this.alterUserDetails.bind(this, "lastName")}
								label={"Nachname"}
								fullWidth
							/>
						</div>
					</div>
					<div className={this.props.classes.inputGroup}>
						<div className={this.props.classes.dialogInput}>
							<TextField
								value={this.state.editUser.rocketChat}
								onChange={this.alterUserDetails.bind(this, "rocketChat")}
								label={"Rocket.Chat"}
								fullWidth
							/>
						</div>
						<div className={this.props.classes.dialogInput}>
							<TextField
								value={this.state.editUser.loxoneId}
								onChange={this.alterUserDetails.bind(this, "loxoneId")}
								label={"Loxone"}
								fullWidth
							/>
						</div>
					</div>
					<div className={this.props.classes.inputGroup}>
						<div className={this.props.classes.dialogInput}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="dd. MM. yyyy"
									id="date-picker-entryDate"
									label="Eintrittstag"
									value={this.state.editUser.entryDate ? this.state.editUser.entryDate : null}
									onChange={(date): void => this.changeEntryDate(date)}
									KeyboardButtonProps={{
										'aria-label': 'change entry date',
									}}
									className={classes.entryDate}
									autoOk={true}
								/>

							</MuiPickersUtilsProvider>
						</div>
					</div>
					<div>
						<FormControlLabel
							control={
								<Checkbox
									onChange={this.alterUserDetails.bind(this, "isAdmin")}
									checked={this.state.editUser.isAdmin}
								/>
							}
							label="Administrator"
						/>
					</div>
				</div>
				<div className={this.props.classes.listBlock}>
					<Typography variant={'h4'}>Standardarbeitszeiten</Typography>
					<Button
						onClick={(): void => {
							this.setState({workdaysDialogOpen: true, workingWeekdaysToEdit: null});
						}}
						variant="contained"
						color="secondary"
						style={{marginTop: '16px'}}
						disabled={this.state.isLoading}>
						{'Neue Arbeitszeit'}
					</Button>
					<Table style={{margin: "15px"}}>
						<TableHead>
							<TableRow>
								<TableCell>Zeitraum</TableCell>
								<TableCell>Arbeitszeiten</TableCell>
								<TableCell style={{width: '10%'}}>Optionen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.editUser?.workingWeekdays?.map((workingWeekdays: WorkingWeekdays, index: number) =>
								<TableRow key={index}>
									<TableCell key={index + 'date'}>
										{moment(workingWeekdays.startDate).format('DD. MM. YYYY')} - {moment(workingWeekdays.endDate).format('DD. MM. YYYY')}
									</TableCell>
									<TableCell key={index + 'weekdays'}>
										{'Montag: ' + workingWeekdays.weekdays[0] + 'h | Dienstag: ' + workingWeekdays.weekdays[1] + 'h | Mittwoch: ' + workingWeekdays.weekdays[2] + 'h | Donnerstag: ' + workingWeekdays.weekdays[3] + 'h | Freitag: ' + workingWeekdays.weekdays[4] + 'h'}
									</TableCell>
									<TableCell>
										<PopupState  key={"popupStateobj"+index} variant="popover" popupId="edit-exceptions-menu">
											{(popupState: Window): ReactNode => (
												<React.Fragment key={"popupState"+index}>
													<IconButton {...bindTrigger(popupState)}>
														<MoreVertIcon />
													</IconButton>
													<Menu {...bindMenu(popupState)}>
														{options.map((option): ReactNode =>(<MenuItem key={option} onClick={async (): Promise<void> =>{
															if(option === "Bearbeiten") {
																this.setState({workdaysDialogOpen: true, workingWeekdaysToEdit: JSON.parse(JSON.stringify(workingWeekdays)), idx: index});
															} else if(option === "Löschen") {
																this.deleteWorkingWeekdays(index);
															}
															popupState.close();
														}}>
															{option}
														</MenuItem>))}
													</Menu>
												</React.Fragment>
											)}
										</PopupState>
									</TableCell>
								</TableRow>
								)}
						</TableBody>
					</Table>
					<WorkdaysModal
						workingWeekdays={this.state.workingWeekdaysToEdit}
						closeHandler={this.closeModal.bind(this)}
						dialogOpen={this.state.workdaysDialogOpen}
						handleWorkingWeekdays={this.handleWorkingWeekdays.bind(this)}
						index={this.state.idx}
					/>
				</div>
				<div className={this.props.classes.listBlock} style={{display: 'none'}}>
					<Typography variant={'h4'}>Ausnahmen</Typography>
					<div className={this.props.classes.timeExceptionGroup}>
						<div className={this.props.classes.timeExceptionInput} style={{display: "contents"}} >
							<div className={classes.buttonContainer}>
								<Button
									variant="contained"
									onClick={(): void => {
										if (this.state.selectedYear !== 0 && this.state.selectedMonth !== 0) {
											this.setState({workTimeExceptionToEdit: null},
												() => this.showWorkTimeExceptionModal(true));
										} else {
											this.setState({selectError: true});
										}
									}}
									className={classes.addWorktimeExceptionButton}
									color="secondary"
									disabled={this.state.isLoading}
								>
									{'Neue Ausnahme'}
								</Button>
								<FormControl
									style={{width: "150px", marginRight: "16px", marginLeft: "16px"}}
									variant='outlined'
								>
									<InputLabel>Monat</InputLabel>
									<Select
										label={'Monat'}
										value={this.state.selectedMonth}
										onChange={(e,c): void => this.selectChanged(e, c, "month")}
									>
										<MenuItem value={1}>Jänner</MenuItem>
										<MenuItem value={2}>Februar</MenuItem>
										<MenuItem value={3}>März</MenuItem>
										<MenuItem value={4}>April</MenuItem>
										<MenuItem value={5}>Mai</MenuItem>
										<MenuItem value={6}>Juni</MenuItem>
										<MenuItem value={7}>Juli</MenuItem>
										<MenuItem value={8}>August</MenuItem>
										<MenuItem value={9}>September</MenuItem>
										<MenuItem value={10}>Oktober</MenuItem>
										<MenuItem value={11}>November</MenuItem>
										<MenuItem value={12}>Dezember</MenuItem>
									</Select>
									{
										this.state.selectError? <FormHelperText className={this.props.classes.selectError}>Bitte ausfüllen!</FormHelperText>: ""
									}
								</FormControl>
								<FormControl
									style={{width: "150px"}}
									variant='outlined'>
									<InputLabel>Jahr</InputLabel>
									<Select
										label={'Jahr'}
										value={this.state.selectedYear}
										onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>,c: ReactNode): void =>this.selectChanged(e,c,"year")}
									>
										{
											this.getNext3Years().map((year: number): ReactNode =>(
												<MenuItem key={"year_"+year} value={year}>{year}</MenuItem>
											))
										}
									</Select>
									{this.state.selectError && <FormHelperText className={this.props.classes.selectError}>Bitte ausfüllen!</FormHelperText>}
								</FormControl>
							</div>
							<Table style={{margin: "15px"}}>
								<TableHead>
									<TableRow>
										<TableCell>Zeitraum</TableCell>
										<TableCell style={{width: '10%'}}>Optionen</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.MonthsWithExceptions}
								</TableBody>
							</Table>

							<Dialog open={this.state.editWorkTimeExceptions}
								onExited={(): void => this.getMonthsWithExceptions()}>
								<WorkTimeExceptionModal
									isVisible={this.showWorkTimeExceptionModal.bind(this)}
									selectedMonth={this.state.selectedMonth}
									selectedYear={this.state.selectedYear}
									workTimeException={this.state.workTimeExceptionToEdit}
									handleWorkTimeExceptions={this.handleWorkTimeExceptions.bind(this)}
									selectedUser={this.state.editUser}
								/>
							</Dialog>
						</div>
					</div>
				</div>
				<div className={this.props.classes.listBlock}>
					<Typography variant={'h4'}>Sonderurlaub</Typography>
					<Button
						onClick={(): void => {
							this.setState({specialVacationDialogOpen: true, specialVacationToEdit: null});
						}}
						variant="contained"
						color="secondary"
						style={{marginTop: '16px'}}
						disabled={this.state.isLoading}>
						{'Neuer Sonderurlaub'}
					</Button>
					<Table style={{margin: "15px"}}>
						<TableHead>
							<TableRow>
								<TableCell>Zeitraum</TableCell>
								<TableCell>Anzahl Sonderurlaubstage</TableCell>
								<TableCell style={{width: '10%'}}>Optionen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.editUser?.specialVacations.map((specialVacation: SpecialVacation, index: number) =>
								<TableRow key={index}>
									<TableCell key={index + 'date'}>
										{EditUserModal.getMonthName((new Date(specialVacation.date).getMonth())+1)} {new Date(specialVacation.date).getFullYear()}
									</TableCell>
									<TableCell key={index + 'days'}>
										{specialVacation.days}
									</TableCell>
									<TableCell>
										<PopupState  key={"popupStateobj"+index} variant="popover" popupId="edit-exceptions-menu">
											{(popupState: Window): ReactNode => (
												<React.Fragment key={"popupState"+index}>
													<IconButton {...bindTrigger(popupState)}>
														<MoreVertIcon />
													</IconButton>
													<Menu {...bindMenu(popupState)}>
														{options.map((option): ReactNode =>(<MenuItem key={option} onClick={async (): Promise<void> =>{
															if(option === "Bearbeiten") {
																this.setState({specialVacationDialogOpen: true, specialVacationToEdit: JSON.parse(JSON.stringify(specialVacation))});
															} else if(option === "Löschen") {
																this.deleteSpecialVacation(specialVacation);
															}
															popupState.close();
														}}>
															{option}
														</MenuItem>))}
													</Menu>
												</React.Fragment>
											)}
										</PopupState>
									</TableCell>
								</TableRow>
								)}
						</TableBody>
					</Table>
					<SpecialVacationModal
						specialVacation={this.state.specialVacationToEdit}
						closeHandler={this.closeModal.bind(this)}
						dialogOpen={this.state.specialVacationDialogOpen}
						handleSpecialVacation={this.handleSpecialVacation.bind(this)}
						selectedUser={this.state.editUser}
						specialVacationExists={this.specialVacationExists.bind(this)}
					/>
				</div>
			</div>
		);
	}

	public closeModal(): void {
		this.setState({workdaysDialogOpen: false, specialVacationDialogOpen: false});
	}

	public getNext3Years(): number[] {
		const curYear = new Date().getFullYear();
		const years = [];

		for (let i = 0; i<3;i++) {
			years.push(curYear+i);
		}

		return years;
	}

	public selectChanged(event: React.ChangeEvent<{ name?: string; value: unknown }>, _: ReactNode,selectionType: string): void {
		if (selectionType === "month") {
			this.setState({selectedMonth: parseInt(event.target.value as string)});
		} else if(selectionType === "year") {
			this.setState({selectedYear: parseInt(event.target.value as string)});
		}
	}

	public showWorkTimeExceptionModal(state = true): void {
		this.setState({editWorkTimeExceptions: state});
	}

	private handleWorkingWeekdays(newWorkingWeekdays: WorkingWeekdays, isNew: boolean, index: number): void {
		if (this.state.editUser.workingWeekdays) {
			if (isNew) {
				this.state.editUser.workingWeekdays.push(newWorkingWeekdays);
			} else {
				const currentUser = this.state.editUser;
				if (currentUser.workingWeekdays) {
					currentUser.workingWeekdays[index] = newWorkingWeekdays;
					this.setState({editUser: currentUser});
				}
			}
		}
	}

	private handleSpecialVacation(newSpecialVacation: SpecialVacation): void {
		const currentUser = this.state.editUser;
		const specialVacations: SpecialVacation[] = [];
		const newSpecialVacationDate = new Date(newSpecialVacation.date);

		for (const specialVacation of currentUser.specialVacations) {
			const specialVacationDate = new Date(specialVacation.date);
			if (specialVacationDate.getMonth() === newSpecialVacationDate.getMonth() && specialVacationDate.getFullYear() === newSpecialVacationDate.getFullYear()) {
				specialVacations.push(newSpecialVacation);
			} else {
				specialVacations.push(specialVacation);
			}
		}
		if (!this.specialVacationExists(newSpecialVacation)) {
			specialVacations.push(newSpecialVacation);
		}

		currentUser.specialVacations = specialVacations;
		this.setState({editUser: currentUser});
	}

	private handleWorkTimeExceptions(newWorkTimeExceptions: {date: Date; hours: number}[]): void {
		if (this.state.editUser.worktimeExceptions) {

			const currentUser = this.state.editUser;
			if (currentUser.worktimeExceptions) {

				const workTimeExceptionsToSave: {date: Date; hours: number}[] = [];

				for (const currentWorkTimeException of currentUser.worktimeExceptions) {
					if (!(new Date(currentWorkTimeException.date).getFullYear() === this.state.selectedYear && new Date(currentWorkTimeException.date).getMonth() + 1 === this.state.selectedMonth)) {
						workTimeExceptionsToSave.push(currentWorkTimeException);
					}
				}

				for (const workTimeException of newWorkTimeExceptions) {
					workTimeExceptionsToSave.push(workTimeException);
				}

				currentUser.worktimeExceptions = workTimeExceptionsToSave;
				this.setState({editUser: currentUser});
			}
		}

	}

	private deleteWorkingWeekdays(index: number): void {
		const currentUser = this.state.editUser;
		if (currentUser.workingWeekdays) {
			currentUser.workingWeekdays.splice(index, 1);
		}
		this.setState({editUser: currentUser});
	}

	private deleteWorkTimeExceptions(year: number, month: number): void {
		const workTimeExceptionsToSave: {date: Date; hours: number}[] = [];
		const currentUser = this.state.editUser;
		if (currentUser.worktimeExceptions) {
			for (const currentWorkTimeException of currentUser.worktimeExceptions) {
				if (!(new Date(currentWorkTimeException.date).getFullYear() === year && new Date(currentWorkTimeException.date).getMonth() + 1 === month)) {
					workTimeExceptionsToSave.push(currentWorkTimeException);
				}
			}

			currentUser.worktimeExceptions = workTimeExceptionsToSave;
			this.setState({editUser: currentUser});
		}
	}

	private deleteSpecialVacation(specialVacationToDelete: SpecialVacation): void {
		const currentUser = this.state.editUser;
		const specialVacations = [];
		const specialVacationToDeleteDate = new Date(specialVacationToDelete.date);

		for (const specialVacation of currentUser.specialVacations) {
			const specialVacationDate = new Date(specialVacation.date);
			if (!(specialVacationDate.getMonth() === specialVacationToDeleteDate.getMonth() && specialVacationDate.getFullYear() === specialVacationToDeleteDate.getFullYear())) {
				specialVacations.push(specialVacation);
			}
		}
		currentUser.specialVacations = specialVacations;
		this.setState({editUser: currentUser});
	}

	private closeEditor(): void {
		this.props.closeHandler();
	}

	private saveUser(): void {
		this.setState({isLoading: true});
		this.authService.createOrChangeUser(this.state.editUser).then((): void => {
			this.props.closeHandler();
			this.setState({isLoading: false});
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div>
				<DialogTitle>
					{`Benutzer ${this.state.editUser.firstName} ${this.state.editUser.lastName} bearbeiten`}
				</DialogTitle>
				<DialogContent className={classes.dialogBox}>
					<Typography>Allgemein</Typography>
					{this.buildInputElementsByKey()}
					<div className={classes.progressContainer}>
						{this.state.isLoading &&
						<CircularProgress className={classes.progress} />
						}
					</div>
				</DialogContent>

				<DialogActions style={{marginBottom: '16px'}}>
					<Button
						onClick={this.closeEditor.bind(this)}
						variant="outlined"
						color="secondary"
						disabled={this.state.isLoading}
					>
						{'Abbrechen'}
					</Button>
					<Button
						onClick={this.saveUser.bind(this)}
						variant="contained"
						color="secondary"
						disabled={this.state.isLoading}
					>
						{'Speichern'}
					</Button>
				</DialogActions>
			</div>
		);
	}

	private static getMonthName(selectedMonth: number): string {
		const months: string[] = [
			"Kein",
			"Jänner",
			"Februar",
			"März",
			"April",
			"Mai",
			"Juni",
			"Juli",
			"August",
			"September",
			"Oktober",
			"November",
			"Dezember"
		];

		return months[selectedMonth];
	}

	private getMonthsWithExceptions(): void {
		const nodes: ReactNode[] = [];
		const options = ["Bearbeiten", "Löschen"];
		if (this.state.editUser.worktimeExceptions) {

			const existingMonths: {[index: number]: {[index: number]: {}}} = {};

			for (const currentWorkTimeException of this.state.editUser.worktimeExceptions) {
				if(existingMonths[moment(currentWorkTimeException.date).year()] === undefined){
					existingMonths[moment(currentWorkTimeException.date).year()] = {};
				}
				existingMonths[moment(currentWorkTimeException.date).year()][moment(currentWorkTimeException.date).month()] = {};
			}

			const dateData: {year: number;month: number}[] = [];

			Object.keys(existingMonths).forEach((yearVal: string): void => {
				Object.keys(existingMonths[parseInt(yearVal)]).forEach((monthVal: string): void => {
					dateData.push({year: parseInt(yearVal),month:parseInt(monthVal)});
				});
			});

			dateData.forEach((date,idx): void => {
				nodes.push(
					<TableRow key={idx}>
						<TableCell key={idx+"_"}>
							{EditUserModal.getMonthName((date.month)+1)} {date.year}
						</TableCell>
						<TableCell>
							<PopupState  key={"popupStateobj"+idx} variant="popover" popupId="edit-exceptions-menu">
								{(popupState: Window): ReactNode => (
									<React.Fragment key={"popupState"+idx}>
										<IconButton {...bindTrigger(popupState)}>
											<MoreVertIcon />
										</IconButton>
										<Menu {...bindMenu(popupState)}>
											{options.map((option): ReactNode =>(<MenuItem key={option} onClick={async (): Promise<void> =>{
												if(option === "Bearbeiten"){
													this.setState({selectedYear: date.year, selectedMonth: date.month+1, workTimeExceptionToEdit: this.getWorkTimeExceptionsByMonthAndYear(date.year, date.month+1)},
														() => this.showWorkTimeExceptionModal(true));
												}else if(option === "Löschen"){
													this.deleteWorkTimeExceptions(date.year, date.month + 1);
													this.getMonthsWithExceptions();
												}
												popupState.close();
											}}>
												{option}
											</MenuItem>))}
										</Menu>
									</React.Fragment>
								)}
							</PopupState>
						</TableCell>
					</TableRow>
				);

			});

			this.setState({MonthsWithExceptions: nodes});
		}

	}

	private getWorkTimeExceptionsByMonthAndYear(year: number, month: number): {date: Date; hours: number}[] {
		let workTimeExceptions: {date: Date; hours: number}[] = [];

		if (this.state.editUser && this.state.editUser.worktimeExceptions) {

			workTimeExceptions = this.state.editUser.worktimeExceptions.filter((exceptionObject: {date: Date; hours: number}): boolean => {
				return moment(exceptionObject.date).month()+1 === month &&
						moment(exceptionObject.date).year() === year;
			});
		}
		return workTimeExceptions;
	}

	private specialVacationExists(newSpecialVacation: SpecialVacation): boolean {

		for (const specialVacation of this.state.editUser.specialVacations) {
			const specialVacationDate = new Date(specialVacation.date);
			const newSpecialVacationDate = new Date(newSpecialVacation.date);
			if (specialVacationDate.getMonth() === newSpecialVacationDate.getMonth() && specialVacationDate.getFullYear() === newSpecialVacationDate.getFullYear()) {
				return true;
			}
		}
		return false;
	}

}

export default withStyles(editUserStyles)(EditUserModal);
