import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const loxoneTimesModalStyles = (theme: Theme): StyleRules<string> => createStyles({
	dialogBox: {
		height: "fit-content",
		width: "fit-content"
	},
	loxoneTimeSelector: {
		margin: "15px"
	},
	primaryButton: {
		backgroundColor: "#131a2e",
		color: "white",
		"&:hover":{
			backgroundColor: "#07070e",
			color: "white"
		}
	},
	secondaryButton: {
		backgroundColor: "#375184",
		color: "white",
		"&:hover":{
			backgroundColor: "#12122b",
			color: "white"
		}
	}
});

export default loxoneTimesModalStyles;
