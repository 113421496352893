import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const editPublicHolidayStyles = (theme: Theme): StyleRules<string> => createStyles({
	dialogBox: {
		height: "fit-content",
		width: "fit-content"
	}
});

export default editPublicHolidayStyles;
