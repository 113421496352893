import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const loginStyles = (theme: Theme): StyleRules<string> => createStyles({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
		backgroundColor: '#ffffff',
		padding: '25px',
		boxShadow: '12px 13px 26px -10px rgba(82,82,82,1)',
	},
	submit: {
		float: 'right',
		backgroundColor: '#131a2e',
		width: 'min-content'
	},
	logo: {
		margin: '40px',
	},
});

export default loginStyles;
