import React, { Component, ReactNode } from 'react';
import moment from 'moment';

import { WithStyles, withStyles } from '@material-ui/core';

import TrackingSummaryPrinterComponent from './SummaryPrinter/TrackingSummaryPrinter';
import TimeTrackingTableRowPrinterComponent from './TableRowPrinter/TableRowPrinter';
import TrackingTableFooterPrinter from './TableFooterPrinter/TableFooterPrinter';
import { ReactComponent as Logo } from '../../Authentication/logo.svg';

import TimeTrackingMonth  from '../DataModels/TimeTrackingMonth';
import { UserDataModel } from './../../Admin/UserData.Model';
import trackingPrinterStyles from './TrackingPrinterStyles';
import TimeTrackingDay from '../DataModels/TimeTrackingDay';


interface Props extends WithStyles<typeof trackingPrinterStyles> {
	currentMonthData: TimeTrackingMonth;
	previousMonthData: TimeTrackingMonth;
	// TODO: rename to userData or similiar
	user: UserDataModel;
	timeTrackingMonths: TimeTrackingMonth[];

}

class TrackingPrinter extends Component<Props> {

	private getPreviousDay(index: number): TimeTrackingDay | undefined {
		if(index === 0 && this.props.previousMonthData){
			return this.props.previousMonthData.days[this.props.previousMonthData.days.length-1];
		}else if(index > 0 && this.props.currentMonthData){
			return this.props.currentMonthData.days[index-1];
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		const date = moment({year: this.props.currentMonthData.year, month: this.props.currentMonthData.month});

		// TODO: remove inline CSS
		// TODO: why is it inclosed in a span and not a div?
		return (
			<div className={`${classes.printerOverlay} bc-printable `}>

				<Logo className={classes.printLogo} />

				<span style={{right: "5%", position: "absolute"}}>ELEPHANTS 5, Hauptplatz 17, A-4050 Traun</span>

				<div className={this.props.classes.generalInfo} >
					<b>Stundennachweis</b>
					<b>{`${this.props.user.firstName} ${this.props.user.lastName}`}</b>
					<b>{date.format("MMMM YYYY")}</b>
				</div>
				<div className={classes.root}>
					<table>
						<thead>
							<tr>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.dayCell}`}>Tag</td>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.timeCell}`}>Von</td>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.timeCell}`}>Bis</td>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.timeCell}`}>Von</td>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.timeCell}`}>Bis</td>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.sumCell}`}>Soll</td>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.sumCell}`}>Ist</td>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.sumCell}`}>Diff.</td>
								<td className={`${classes.boldCell} ${classes.textMiddle} ${classes.commentCell}`}>Bemerkung</td>
							</tr>
						</thead>
						<tbody>
							{this.props.currentMonthData.days.map((value, index): ReactNode => (
								<TimeTrackingTableRowPrinterComponent
									dayData={value}
									previousDayData={this.getPreviousDay(index)}
									key={"rowComp"+index}
								/>
							))}
						</tbody>
						<TrackingTableFooterPrinter
							actualSum={this.props.currentMonthData.actualSum}
							requiredSum={this.props.currentMonthData.requiredSum}
						/>
					</table>
				</div>

				<TrackingSummaryPrinterComponent
					currentMonthData={this.props.currentMonthData}
					previousMonthData={this.props.previousMonthData}
					user={this.props.user}
					timeTrackingMonths={this.props.timeTrackingMonths}
				/>
				<span style={{ float: 'left', display: 'flex' }}>
					<div style={{left: "5%", position: 'absolute', bottom: 0, borderTop: "1px solid black"}}>
						Unterschrift Arbeitgeber
					</div>
					<div style={{right: "5%", position: 'absolute', bottom: 0, borderTop: "1px solid black"}}>
						Unterschrift Arbeitnehmer
					</div>
				</span>
			</div>
		);
	}
}

export default withStyles(trackingPrinterStyles)(TrackingPrinter);
