import React, { Component, ReactNode } from 'react';

import { Paper, Table, TableBody, TableCell, TableRow, withStyles, WithStyles } from '@material-ui/core';

import { UserDataModel } from '../../Admin/UserData.Model';
import Utilities from '../../Utilities';
import TimeTrackingMonth from '../DataModels/TimeTrackingMonth';
import trackingSummaryStyles from './TrackingSummaryStyles';

interface Props extends WithStyles<typeof trackingSummaryStyles> {
	previousMonthData: TimeTrackingMonth;
	currentMonthData: TimeTrackingMonth;
	user: UserDataModel | null;
	timeTrackingMonths: TimeTrackingMonth[];
}

interface State{
	previousMonthData: TimeTrackingMonth;
	currentMonthData: TimeTrackingMonth;
}

class TrackingSummary extends Component<Props, State>{

	public constructor(props: Props){
		super(props);

		this.state = {
			previousMonthData: this.props.previousMonthData,
			currentMonthData: this.props.currentMonthData
		};
	}

	public async componentWillReceiveProps(nextProps: Readonly<Props>): Promise<void> {
		this.setState({previousMonthData: nextProps.previousMonthData,
			currentMonthData: nextProps.currentMonthData,
		});

		this.forceUpdate();
	}

	public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>): boolean {
		return (
			nextProps.previousMonthData !== this.props.previousMonthData ||
			nextProps.currentMonthData !== this.props.currentMonthData
		);
	}

	private getFutureEntryDate(): string {
		if (this.props.user) {
			const entryDate = new Date(this.props.user.entryDate);
			const currentFirstOfMonth = new Date(this.props.currentMonthData.year, this.props.currentMonthData.month);
			const entryDateAsDate = new Date(entryDate);
			const entryDateFirstOfMonth = new Date(entryDateAsDate.getFullYear(), entryDateAsDate.getMonth());

			while (entryDateFirstOfMonth < currentFirstOfMonth) {
				entryDateFirstOfMonth.setFullYear(entryDateFirstOfMonth.getFullYear() + 1);
			}
			entryDateFirstOfMonth.setDate(entryDateAsDate.getDate());
			return  entryDateFirstOfMonth.toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit', year: 'numeric'});
		}
		return '';
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<Paper className={`${this.props.classes.summaryCardWrapper} bc-unprintable`}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>Eintrittstag</TableCell>
							<TableCell className={classes.borderCell}>{(this.props.user && this.props.user.entryDate) ? new Date(this.props.user.entryDate).toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit', year: 'numeric'}) : 'Nicht eingetragen'}</TableCell>
							<TableCell>Jahresurlaub (bis {this.getFutureEntryDate()})</TableCell>
							<TableCell align="center" className={classes.borderCell}>{Utilities.printHours(this.state.previousMonthData.vacationYearBalance)}</TableCell>
							<TableCell>Urlaub-Konto (Aliquot)</TableCell>
							<TableCell align="center" className={classes.borderCell}>{Utilities.printHours(this.state.previousMonthData.vacationBalance)}</TableCell>
							<TableCell>ZA-Konto</TableCell>
							<TableCell>{Utilities.printHours(this.state.previousMonthData.overtimeBalance)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell></TableCell>
							<TableCell className={classes.borderCell} />
							<TableCell>Jahresurlaub-Veränderung</TableCell>
							<TableCell className={classes.borderCell}>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell>+ {Utilities.printHours(this.state.currentMonthData.getYearlyAddedVacationYearDays(this.props.user?.workingWeekdays, this.props.user?.entryDate) + this.state.currentMonthData.getVacationYearDaysToAdd(this.props.user?.workingWeekdays, this.props.user?.entryDate, this.props.timeTrackingMonths, 'add') + this.state.currentMonthData.getSpecialVacationDaysThisMonth(this.props.user?.specialVacations))}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>- {Utilities.printHours(Math.abs(this.state.currentMonthData.getConsumedVacationDaysThisMonth() + this.state.currentMonthData.getVacationYearDaysToAdd(this.props.user?.workingWeekdays, this.props.user?.entryDate, this.props.timeTrackingMonths, 'subtract')))}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableCell>
							<TableCell>Urlaub-Konto-Veränderung</TableCell>
							<TableCell className={classes.borderCell}>
								<Table>
									<TableBody>
										<TableRow>
											<TableCell>+ {Utilities.printHours(this.state.currentMonthData.getSpecialVacationDaysThisMonth(this.props.user?.specialVacations) + this.state.currentMonthData.getVacationDaysThisMonth(this.props.user?.workingWeekdays))}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>- {Utilities.printHours(this.state.currentMonthData.getConsumedVacationDaysThisMonth())}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableCell>
							<TableCell>ZA-Veränderung</TableCell>
							<TableCell>{Utilities.printHours(this.state.currentMonthData.overtimeChangeThisMonth)}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell></TableCell>
							<TableCell className={classes.borderCell}></TableCell>
							<TableCell>Jahresurlaub-Übertrag</TableCell>
							<TableCell align="center" className={classes.borderCell}>{Utilities.printHours(this.state.previousMonthData.vacationYearBalance-this.state.currentMonthData.getConsumedVacationDaysThisMonth()+this.state.currentMonthData.getSpecialVacationDaysThisMonth(this.props.user?.specialVacations)+this.state.currentMonthData.getYearlyAddedVacationYearDays(this.props.user?.workingWeekdays, this.props.user?.entryDate) + this.state.currentMonthData.getVacationYearDaysToAdd(this.props.user?.workingWeekdays, this.props.user?.entryDate, this.props.timeTrackingMonths, 'all'))} </TableCell>
							<TableCell>Urlaub-Konto-Übertrag</TableCell>
							<TableCell align="center" className={classes.borderCell}>
								{Utilities.printHours(this.state.previousMonthData.vacationBalance+this.state.currentMonthData.getUsedVacationThisMonth(this.props.user?.workingWeekdays, this.props.user?.specialVacations))}
							</TableCell>
							<TableCell>ZA-Übertrag</TableCell>
							<TableCell>
								{Utilities.printHours(this.state.previousMonthData.overtimeBalance+this.state.currentMonthData.overtimeChangeThisMonth)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
		);
	}
}

export default withStyles(trackingSummaryStyles)(TrackingSummary);
