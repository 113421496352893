import React, { ReactNode, Component } from 'react';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';


import {
	Button,
	ButtonGroup,
	Menu,
	MenuItem
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import AuthService from '../Common/AuthService';
import { UserDataModel } from '../Admin/UserData.Model';

interface State {
	users: UserDataModel[];
}


class LoginAsButton extends Component<object, State> {

	private authService: AuthService;

	public constructor(props: object) {
		super(props);
		this.authService = new AuthService();
		this.state = {
			users: []
		};
	}

	public async componentDidMount(): Promise<void> {
		await this.loadUsers();
	}

	private async loadUsers(): Promise<void> {
		const users = await this.authService.getAllUsers()
		this.setState({users: users});
	}

	public render(): ReactNode {
		if (!this.authService.isAdmin() && this.authService.getOriginalEmail() === '') {
			return (<React.Fragment />);
		}

		if (this.authService.getOriginalEmail() !== '') {
			return (
				<Button
					variant="contained"
					color="secondary"
					onClick={ (): void => {
						this.loginAsUser(this.authService.getOriginalEmail());
					}}
				>Zurückwechseln</Button>
			);
		}

		return (
			<PopupState variant="popover" popupId="edit-exceptions-menu">
				{(popupState: Window): ReactNode => (
					<React.Fragment>
						<ButtonGroup variant="contained" color="secondary">
							<Button>Anmelden als</Button>
							<Button
								color="secondary"
								size="small"
								{...bindTrigger(popupState)}
							>
								<ArrowDropDownIcon />
							</Button>
						</ButtonGroup>
						<Menu {...bindMenu(popupState)}>
							{this.state.users.map((user): ReactNode =>(
								<MenuItem
									key={user.email}
									onClick={ (): void => {
										this.loginAsUser(user.email);
										popupState.close();
									}}
								>
									{user.email}
								</MenuItem>
							))}
						</Menu>
					</React.Fragment>
				)}
			</PopupState>
		);
	}

	private loginAsUser(email: string): void {
		this.authService.loginAs(email).then(() => {
			window.location.href = '/';
		});
	}
}

export default LoginAsButton;
