import React, { ReactNode, Component } from 'react';
import { Redirect } from 'react-router-dom';

import AuthService from '../Common/AuthService';

class Logout extends Component<object> {

	private authService: AuthService;

	public constructor(props: object) {
		super(props);
		this.authService = new AuthService();
	}

	public componentWillMount(): void {
		this.authService.logout();
	}

	public render(): ReactNode {
		return (<Redirect to={{ pathname: "/login" }} />);
	}
}

export default Logout;
