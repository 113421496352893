import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';

const drawerWidth = 240;

// TODO: each component should have it's own Styles.tsx file
// Split this component up into the corresponding files
// Remove unused styles (less code == easier to understand and maintain)
const appStyles = (theme: Theme): StyleRules<string> =>
	createStyles({
		root: {
			backgroundColor: "rgb(224,224,224)"
		},
		logout: {
			margin: "15px"
		},
		'body':{
			backgroundColor: "#e0e0e0"
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			float: "right",
		},
		container: {
			padding: theme.spacing(1),
			paddingLeft: theme.spacing(2)
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			backgroundColor: "rgb(224,224,224)"
		},
		drawerPaper: {
			width: drawerWidth
		},
		headline: {
			flex: 1
		},
		headerButton: {
			margin: "15px"
		},
		toolbar: theme.mixins.toolbar,

		dialogInput: {
			padding: "10px",
			flexGrow: 2,
			flexBasis: "200px",
			width: "50%",
			float: "left",
			margin: 0
		},
		weekdayInput: {
			float: "left",
			padding: "10px"
		},
		inputGroup: {
			display: "flex"
		},
		timeExceptionGroup: {
			display: "block"
		},
		timeExceptionInput: {
			float: "left"
		},
		deleteButton: {
			backgroundColor: "red",
			"&:hover": {
				backgroundColor: "#7d0000",
				color: "white"
			}
		},
		summaryCard: {
			width: "300px",
			display: "inline-block",
			margin: "25px"
		},
		negativeHours: {
			color: "#F00"
		},
		trackingPrint:{
			display: "block !important"
		},
		tableHeader: {
			textAlign: "center"
		},
		addWorktimeExceptionButton:{
			float: "right",
			marginTop: "20px"
		},
		publicHoliday:{
			backgroundColor: "#97beff"
		}
	});

export default appStyles;
