import { Theme, createStyles} from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const workdaysStyles = (theme: Theme): StyleRules<string> => createStyles({
	container: {
		padding: '50px',
		width: '100%'
	},
	dates: {
		paddingBottom: '30px'
	},
	startDate: {
		paddingRight: '20px'
	},
	worktimeText: {
		paddingBottom: '10px'
	},
	weekdaysContainer: {
		display: 'flex',
		flexDirection: 'row',
		paddingBottom: '30px',
		flexWrap: 'wrap'
	},
	weekdayInput: {
		width: '100px',
		marginRight: '30px',
		paddingBottom: '10px'
	},
	bottomRow: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '20px'
	},
	initValues: {
		width: '252px',
	}
});

export default workdaysStyles;
