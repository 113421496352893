import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const worktimeExceptionStyles = (theme: Theme): StyleRules<string> => createStyles({
	weekDivider: {
		borderBottom: "2px solid black",
		fontWeight: "bolder",
		fontSize: "12px",
		backgroundColor: "#e2e2e2"
	},
	primaryButton: {
		backgroundColor: "#131a2e",
		color: "white",
		"&:hover":{
			backgroundColor: "#07070e",
			color: "white"
		}
	},
	secondaryButton: {
		backgroundColor: "#375184",
		color: "white",
		"&:hover":{
			backgroundColor: "#12122b",
			color: "white"
		}
	},
	dialogBox: {
		height: "fit-content",
		width: "fit-content"
	},
});

export default worktimeExceptionStyles;
