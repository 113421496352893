import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';


const trackingStyles = (theme: Theme): StyleRules<string> =>
	createStyles({
		root: {
			'& table': {
				borderCollapse: 'collapse',
				border: "1px solid black",
				borderSpacing: 0,
				margin: "auto",
				marginTop: "20px",
				width: "min-content",
				'& tbody':{
					'& tr':{
						'& td':{
							borderCollapse: 'collapse',
							border: "1px solid black",
							borderSpacing: 0,
							textAlign: "end",
							paddingRight: "4px",
							maxWidth: "100px",
							padding: "14px 25px 14px 16px",
							background: "red"
						}
					}
				}
			}
		},
		loadingWrapper:{
			position: "absolute",
			top: "0",
			bottom: "0",
			left: "0",
			right: "0",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		fab: {
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(2)
		},
		fabPrint:{
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(10)
		},
		fabMonth: {
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(18)
		},
		table: {
			width: "100%"
		},
		tableCell:{
			padding: "14px 25px 14px 16px",
			background: "red"
		}
	});

export default trackingStyles;
