import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';


const vacationEmployeesStyles = (theme: Theme): StyleRules<string> =>
	createStyles({
		fabPrint:{
			position: 'fixed',
			bottom: theme.spacing(2),
			right: theme.spacing(2)
		},
		reloadButton: {
			marginLeft: theme.spacing(2)
		},
		filterContainer: {
			display: 'flex',
			alignItems: 'center',
			marginBottom: theme.spacing(2)
		}
	});

export default vacationEmployeesStyles;
