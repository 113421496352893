import JWT from "jwt-decode";
import { UserDataModel } from "../Admin/UserData.Model";

interface JWTPayload {
	exp: number;
	isAdmin: boolean;
	email: string;
	originalEmail: string;
}

interface LoginResponse extends Response {
	success: boolean;
	message?: string;
	token?: string;
}

interface UserResponse extends Response {
	success: boolean;
	data: UserDataModel[];
	message?: string;
}

export interface CurrentUserResponse {
	data: UserDataModel;
	success: boolean;
}

class AuthService {
	public constructor() {
		this.fetch = this.fetch.bind(this);
		this.login = this.login.bind(this);
	}

	public async getAllUsers(): Promise<UserDataModel[]> {

		return this.fetch<UserResponse>('/api/users/getAllUsers', {
			method: 'GET'
		}).then((response): UserDataModel[] => {
			if (response.success) {
				if (response.data) {
					return response.data;
				} else {
					return [];
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	public login(email: string, password: string): Promise<LoginResponse> {
		return this.fetch<LoginResponse>(`/api/users/login`, {
			method: "POST",
			body: JSON.stringify({
				email,
				password
			})
		}).then(
			(response: LoginResponse): Promise<LoginResponse> => {
				if (response.success && response.token !== undefined) {
					this.setToken(response.token);
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error("Unknown Error");
				}
				return Promise.resolve(response);
			}
		);
	}

	public loginAs(email: string): Promise<LoginResponse> {
		return this.fetch<LoginResponse>(`/api/users/loginAsUser`, {
			method: "POST",
			body: JSON.stringify({
				email
			})
		}).then(
			(response: LoginResponse): Promise<LoginResponse> => {
				if (response.success && response.token !== undefined) {
					this.setToken(response.token);
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error("Unknown Error");
				}
				return Promise.resolve(response);
			}
		);
	}

	public loggedIn(): boolean {
		const token = this.getToken();
		return !!token && !this.isTokenExpired(token);
	}

	public isAdmin(): boolean {
		const token = this.getToken();

		if(!token){
			return false;
		}

		try {
			const decoded: JWTPayload = JWT(token);
			return decoded.isAdmin;

		} catch (err) {
			return false;
		}
	}

	public getUserEmail(): string {
		const token = this.getToken();

		if (!token) {
			return '';
		}

		try {
			const decoded: JWTPayload = JWT(token);
			return decoded.email || '';

		} catch (err) {
			return '';
		}
	}

	public getOriginalEmail(): string {
		const token = this.getToken();

		if (!token) {
			return '';
		}

		try {
			const decoded: JWTPayload = JWT(token);
			return decoded.originalEmail || '';

		} catch (err) {
			return '';
		}
	}


	public isTokenExpired(token: string): boolean {
		try {
			const decoded: JWTPayload = JWT(token);
			if (decoded.exp < Date.now() / 1000) {
				return true;
			} else return false;
		} catch (err) {
			return false;
		}
	}

	private setToken(idToken: string): void {
		localStorage.setItem("id_token", idToken);
	}

	private getToken(): string | null {
		return localStorage.getItem("id_token");
	}

	public logout(): void {
		localStorage.removeItem("id_token");
	}

	public fetch<T>(url: string, options: object): Promise<T> {
		const headers: Record<string, string> = {
			//'Accept': 'application/json',
			"Content-Type": "application/json"
		};

		if (this.loggedIn()) {
			headers["Authorization"] = "Bearer " + this.getToken();
		}

		return (
			fetch(url, { headers, ...options })
				.then(this.checkStatus.bind(this))
				.then((response): Promise<T> => response.json() as Promise<T>)
		);
	}

	public createOrChangeUser(user: UserDataModel): Promise<string> {
		return this.fetch<string>("/api/users/createOrChangeUser", {
			method: "POST",
			body: JSON.stringify(user)
		});
	}

	public loadUser(user: UserDataModel): Promise<UserDataModel> {
		return this.fetch<UserDataModel>(`/api/users/${user._id}`, {
			method: "GET"
		});
	}

	public deleteUser(user: UserDataModel): Promise<string> {
		return this.fetch<string>("/api/users/deleteUser", {
			method: "POST",
			body: JSON.stringify(user)
		});
	}

	public me(): Promise<CurrentUserResponse> {
		return this.fetch<CurrentUserResponse>("/api/users/getCurrentUser", {
			method: "POST",
		});
	}

	private checkStatus(response: Response): Response {
		if (response.status >= 200 && response.status < 300) {
			return response;
		} else {

			if(response.status === 401){ //not logged in
				//redirect to login
				this.logout();
				window.location.replace('/login');
			}

			throw new Error(response.statusText);
		}
	}

	public saveWorktimeExceptions(daysHours: {date: Date; hours: number | null}[], userEmail: string): Promise<string> {
		return this.fetch<string>("/api/users/saveWorktimeExceptions", {
			method: "POST",
			body: JSON.stringify({
				daysHours: daysHours,
				userEmail: userEmail
			})
		});
	}

	public getWorktimeExceptions(year: number, month: number, userEmail: string): Promise<{date: Date; hours: number | null}[]> {

		return this.fetch<{date: Date; hours: number | null} []>("/api/users/getWorktimeExceptions",{
			method: "POST",
			body: JSON.stringify({
				year: year,
				month: month-1,
				userEmail: userEmail
			})
		});
	}

	public getMonthsWithExceptions(userEmail: string): Promise<{[idx: number]: {[i: number]: {}}}>{
		return this.fetch<{[idx: number]: {[i: number]: {}}}>("/api/users/getMonthsWithExceptions",{
			method: "POST",
			body: JSON.stringify({userEmail: userEmail})
		});
	}

	public async deleteWorktimeExceptionMonth(selectedMonth: number, selectedYear: number, email = ""): Promise<void> {

		return this.fetch<void>("/api/users/deleteWorktimeExceptionMonth",{
			method: "POST",
			body: JSON.stringify({
				month: selectedMonth-1,
				year: selectedYear,
				email: email
			})
		});
	}
}

export default AuthService;
