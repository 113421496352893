import moment, { Moment } from 'moment';

import AuthService from './AuthService';
import TimeTrackingMonth from '../Tracking/DataModels/TimeTrackingMonth';
import TimeTrackingDay from '../Tracking/DataModels/TimeTrackingDay';

interface GetTrackedTimesResponse {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	monthData: Record<string, any>;
}

interface GetMultipleTrackedTimesResponse {
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	months: Record<string, any>[];
}

export class LoxoneRESTLog {
	public time: Moment;

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(values: Record<string, any> = {}) {
		this.time = moment(values.time);
	}
}

export class LoxoneRESTResponse {
	public success = false;
	public logs: LoxoneRESTLog[] = [];
}

class TimeTrackingService extends AuthService {

	public async getAllTrackedTimes(): Promise<TimeTrackingMonth[]> {
		const url = '/api/timeTracking/';

		const data = await this.fetch<GetTrackedTimesResponse>(url, {method: "GET"});
		const timeTrackingMonths: TimeTrackingMonth[] = [];
		for (const monthData of data.monthData as TimeTrackingMonth[]) {
			timeTrackingMonths.push(new TimeTrackingMonth(monthData));
		}
		return timeTrackingMonths;
	}

	public async getTrackedTimes(selectedMonth: Moment): Promise<TimeTrackingMonth> {
		const url = `/api/timeTracking/${selectedMonth.year()}-${selectedMonth.month()}`;

		const data = await this.fetch<GetTrackedTimesResponse>(url, {method: "GET"});

		return new TimeTrackingMonth(data.monthData);
	}

	public async getMultipleTrackedTimes(selectedMonth: Moment): Promise<TimeTrackingMonth[]> {
		const url = `/api/timeTracking/multiple/${selectedMonth.year()}-${selectedMonth.month()}`;

		const data = await this.fetch<GetMultipleTrackedTimesResponse>(url, {method: "GET"});
		const months: TimeTrackingMonth[] = [];
		for (const month of data.months) {
			const timeTrackingMonth = new TimeTrackingMonth(month.month);
			timeTrackingMonth.userName = month.userName;
			timeTrackingMonth.userEmail = month.userEmail;
			months.push(timeTrackingMonth);
		}

		return months;
	}

	public saveTrackedTimes(data: TimeTrackingMonth): Promise<void> {
		return this.fetch<void>(`/api/timeTracking/${data.year}-${data.month}`, {
			method: "POST",
			body: JSON.stringify(data)
		});
	}

	// TODO: do we really need to return the response or would it be sufficient to only return the logs array?
	public async getLoxoneData(timeTrackingData: TimeTrackingDay): Promise<LoxoneRESTLog[]> {
		try {
			const data = await this.fetch<LoxoneRESTResponse>(`/api/timeTracking/loxoneData/${timeTrackingData.date.format("YYYY-MM-DD")}`,{
				method: "GET",
			});

			if (data.success) {
				const logs = [];

				for (const jsonLog of data.logs) {
					logs.push(new LoxoneRESTLog(jsonLog));
				}

				return logs.reverse();
			}
		} catch (error) {
			console.log(`Error while reading loxone logs: ${error}`);
		}

		return [];
	}
}

export default TimeTrackingService;
