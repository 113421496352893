import React, { Component, ReactNode } from 'react';

import { WithStyles, withStyles } from '@material-ui/core';


import vacationMonthPrinterStyles from './VacationMonthPrinterStyles';
import { UserDataModel } from './../../Admin/UserData.Model';
import TimeTrackingMonth from '../../Tracking/DataModels/TimeTrackingMonth';
import TimeTrackingDay from '../../Tracking/DataModels/TimeTrackingDay';

interface Props extends WithStyles<typeof vacationMonthPrinterStyles> {
	timeTrackingMonth: TimeTrackingMonth;
	userData: UserDataModel | null;
	timeTrackingMonths: TimeTrackingMonth[];
}

class VacationMonthPrinter extends Component<Props> {
	private months = ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

	private getMonthName(month: number): string {
		return this.months[month];
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<tr className={classes.tableMonth}>
				<td>
					<div className={classes.month}>
						<div><b>{this.getMonthName(this.props.timeTrackingMonth.month) + ' ' + this.props.timeTrackingMonth.year}</b></div>
						<div>Urlaub-Konto (Aliquot):</div>
						<div className={classes.monthValues}>
							<div>Urlaub Monatsbeginn:<b> {(this.props.timeTrackingMonth.vacationBalance - this.props.timeTrackingMonth.getVacationDaysThisMonth(this.props.userData?.workingWeekdays) - this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.userData?.specialVacations) + this.props.timeTrackingMonth.getConsumedVacationDaysThisMonth()).toFixed(2)}</b></div>
							<div>Urlaub erhalten:<b> {this.props.timeTrackingMonth.getVacationDaysThisMonth(this.props.userData?.workingWeekdays).toFixed(2)}</b></div>
							<div>Sonderurlaub erhalten:<b> {this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.userData?.specialVacations)}</b></div>
							<div>Urlaub verbraucht:<b> {this.props.timeTrackingMonth.getConsumedVacationDaysThisMonth()}</b></div>
							<div>Urlaub Monatsende:<b> {this.props.timeTrackingMonth.vacationBalance.toFixed(2)}</b></div>
						</div>
						<div>Jahresurlaub:</div>
						<div className={classes.monthValues}>
							<div>Jahresurlaub Monatsbeginn:<b> {(this.props.timeTrackingMonth.vacationYearBalance + Math.abs(this.props.timeTrackingMonth.getConsumedVacationDaysThisMonth() + this.props.timeTrackingMonth.getVacationYearDaysToAdd(this.props.userData?.workingWeekdays, this.props.userData?.entryDate, this.props.timeTrackingMonths, 'subtract')) - this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.userData?.specialVacations) - (this.props.timeTrackingMonth.getYearlyAddedVacationYearDays(this.props.userData?.workingWeekdays, this.props.userData?.entryDate) + this.props.timeTrackingMonth.getVacationYearDaysToAdd(this.props.userData?.workingWeekdays, this.props.userData?.entryDate, this.props.timeTrackingMonths, 'add') + this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.userData?.specialVacations)))}</b></div>
							<div>Jahresurlaub erhalten:<b> {(this.props.timeTrackingMonth.getYearlyAddedVacationYearDays(this.props.userData?.workingWeekdays, this.props.userData?.entryDate) + this.props.timeTrackingMonth.getVacationYearDaysToAdd(this.props.userData?.workingWeekdays, this.props.userData?.entryDate, this.props.timeTrackingMonths, 'add') + this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.userData?.specialVacations))}</b></div>
							<div>Sonderurlaub erhalten:<b> {this.props.timeTrackingMonth.getSpecialVacationDaysThisMonth(this.props.userData?.specialVacations)}</b></div>
							<div>Jahresurlaub verbraucht:<b> {Math.abs(this.props.timeTrackingMonth.getConsumedVacationDaysThisMonth() + this.props.timeTrackingMonth.getVacationYearDaysToAdd(this.props.userData?.workingWeekdays, this.props.userData?.entryDate, this.props.timeTrackingMonths, 'subtract'))}</b></div>
							<div>Jahresurlaub Monatsende:<b> {this.props.timeTrackingMonth.vacationYearBalance}</b></div>
						</div>
						{this.props.timeTrackingMonth.days.filter((day: TimeTrackingDay) => day.usesZa === 1).map((day: TimeTrackingDay): ReactNode => (
							<div className={classes.vacationDays}>
								<div>{day.date.format('DD. MM. YYYY')}</div>
								<div>{day.publicHoliday && day.publicHoliday.halfDay ? 'Halben Tag': 'Ganzen Tag'}</div>
							</div>
						))}
					</div>
				</td>
			</tr>
		);
	}
}

export default withStyles(vacationMonthPrinterStyles)(VacationMonthPrinter);
