class Utilities {

	public static printHours(input: number | undefined | null): string {
		let definedNumber;

		if (input === undefined || input === null || isNaN(input)) {
			definedNumber = 0;
		} else {
			definedNumber = input;
		}

		return definedNumber.toFixed(2);
	}
}

export default Utilities;
