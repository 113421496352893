import React, { Component, ReactNode } from 'react';

import { withStyles, WithStyles } from '@material-ui/core';

import { UserDataModel } from '../../../Admin/UserData.Model';
import TimeTrackingMonth from '../../DataModels/TimeTrackingMonth';
import Utilities from '../../../Utilities';
import summaryPrinterStyles from './SummaryPrinterStyles';

interface Props extends WithStyles<typeof summaryPrinterStyles> {
	previousMonthData: TimeTrackingMonth;
	currentMonthData: TimeTrackingMonth;
	user: UserDataModel | null;
	timeTrackingMonths: TimeTrackingMonth[];
}

interface State{
	previousMonthData: TimeTrackingMonth;
	currentMonthData: TimeTrackingMonth;
}

class TrackingSummaryPrinter extends Component<Props, State> {
	// TODO: output correct data
	// TODO: why do all cells have the same class?
	// TODO: are appStyles really needed?
	// TODO: remove inlince CSS
	// TODO: use last months data to calculate this months data
	// Calculation should be done in the Model (make a new method that
	// accepts last months data and returns a Summary Object of some sort)

	public constructor(props: Props){
		super(props);

		this.state = {
			previousMonthData: this.props.previousMonthData,
			currentMonthData: this.props.currentMonthData,
		};

	}

	public async componentWillReceiveProps(nextProps: Readonly<Props>): Promise<void> {
		await this.setState({previousMonthData: nextProps.previousMonthData,
			currentMonthData: nextProps.currentMonthData,
		});

		this.forceUpdate();
	}

	public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>): boolean {
		return (
			nextProps.previousMonthData !== this.props.previousMonthData ||
			nextProps.currentMonthData !== this.props.currentMonthData
		);
	}

	private getFutureEntryDate(): string {
		if (this.props.user) {
			const entryDate = new Date(this.props.user.entryDate);
			const currentFirstOfMonth = new Date(this.props.currentMonthData.year, this.props.currentMonthData.month);
			const entryDateAsDate = new Date(entryDate);
			const entryDateFirstOfMonth = new Date(entryDateAsDate.getFullYear(), entryDateAsDate.getMonth());

			while (entryDateFirstOfMonth < currentFirstOfMonth) {
				entryDateFirstOfMonth.setFullYear(entryDateFirstOfMonth.getFullYear() + 1);
			}
			entryDateFirstOfMonth.setDate(entryDateAsDate.getDate());
			return  entryDateFirstOfMonth.toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit', year: 'numeric'});
		}
		return '';
	}

	public render(): ReactNode {

		return (
			<div className={this.props.classes.summaryRoot}>
				<table>
					<tbody>
						<tr>
							<td>Eintrittstag {this.props.user?.entryDate ? new Date(this.props.user?.entryDate).toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit', year: 'numeric'}) : ''}</td>

						</tr>
						<tr>

							<td>Jahresurlaub bis {this.getFutureEntryDate()}</td>
							<td  style={{width: "75px"}}>
								{Utilities.printHours(this.state.previousMonthData.vacationYearBalance)}
							</td>
							<td >Urlaub-Konto (Aliquot)</td>
							<td  style={{width: "75px"}}>
								{Utilities.printHours(this.state.previousMonthData.vacationBalance)}
							</td>
							<td >ZA-Konto</td>
							<td  style={{width: "75px"}}>
								{Utilities.printHours(this.state.previousMonthData.overtimeBalance)}
							</td>
						</tr>
						<tr>
							<td >Jahresurlaub-Veränderung</td>
							<td className={this.props.classes.consumedVacationTable}>
								<table>
									<tbody>
										<tr>
											<td>+ {Utilities.printHours(this.state.currentMonthData.getYearlyAddedVacationYearDays(this.props.user?.workingWeekdays, this.props.user?.entryDate) + this.state.currentMonthData.getVacationYearDaysToAdd(this.props.user?.workingWeekdays, this.props.user?.entryDate, this.props.timeTrackingMonths, 'add') + this.state.currentMonthData.getSpecialVacationDaysThisMonth(this.props.user?.specialVacations))}</td>
										</tr>
										<tr>
											<td>- {Utilities.printHours(Math.abs(this.state.currentMonthData.getConsumedVacationDaysThisMonth() + this.state.currentMonthData.getVacationYearDaysToAdd(this.props.user?.workingWeekdays, this.props.user?.entryDate, this.props.timeTrackingMonths, 'subtract')))}</td>
										</tr>
									</tbody>
								</table>
							</td>
							<td >Urlaub-Konto-Veränderung</td>
							<td className={this.props.classes.consumedVacationTable}>
								<table>
									<tbody>
										<tr>
											<td>+ {Utilities.printHours(this.state.currentMonthData.getSpecialVacationDaysThisMonth(this.props.user?.specialVacations) + this.state.currentMonthData.getVacationDaysThisMonth(this.props.user?.workingWeekdays))}</td>
										</tr>
										<tr>
											<td>- {Utilities.printHours(this.state.currentMonthData.getConsumedVacationDaysThisMonth())}</td>
										</tr>
									</tbody>
								</table>
							</td>
							<td >ZA-Veränderung</td>
							<td >
								{Utilities.printHours(this.state.currentMonthData.overtimeChangeThisMonth)}
							</td>
						</tr>
						<tr>
							<td >Jahresurlaub-Übertrag</td>
							<td >
								{Utilities.printHours(this.state.previousMonthData.vacationYearBalance-this.state.currentMonthData.getConsumedVacationDaysThisMonth()+this.state.currentMonthData.getSpecialVacationDaysThisMonth(this.props.user?.specialVacations)+this.state.currentMonthData.getYearlyAddedVacationYearDays(this.props.user?.workingWeekdays, this.props.user?.entryDate) + this.state.currentMonthData.getVacationYearDaysToAdd(this.props.user?.workingWeekdays, this.props.user?.entryDate, this.props.timeTrackingMonths, 'all'))}
							</td>
							<td >Urlaub-Konto-Übertrag</td>
							<td >
								{Utilities.printHours(this.state.previousMonthData.vacationBalance+this.state.currentMonthData.getUsedVacationThisMonth(this.props.user?.workingWeekdays, this.props.user?.specialVacations))}
							</td>
							<td >ZA-Übertrag</td>
							<td >
								{Utilities.printHours(this.state.previousMonthData.overtimeBalance+this.state.currentMonthData.overtimeChangeThisMonth)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
}

export default withStyles(summaryPrinterStyles)(TrackingSummaryPrinter);
