import moment, { Moment } from 'moment';

import PublicHolidayModel from '../../PublicHolidays/PublicHoliday.Model';

export interface TimeTrackingTimeData {
	fromAM: Moment;
	untilAM: Moment;
	fromPM: Moment;
	untilPM: Moment;
}

class TimeTrackingDay implements TimeTrackingTimeData {
	public date: Moment;
	public fromAM: Moment;
	public untilAM: Moment;
	public fromPM: Moment;
	public untilPM: Moment;

	public comment = '';
	public usesZa = 0;

	public publicHoliday: PublicHolidayModel | null = null;
	public requiredHours = 0;

	public get isDayOff(): boolean {
		return (
			!!this.publicHoliday ||
			this.date.day() === 0 ||
			this.date.day() === 6
		);
	}

	public get actualHours(): number {

		const amDuration = moment.duration(this.untilAM.diff(this.fromAM));
		const pmDuration = moment.duration(this.untilPM.diff(this.fromPM));

		return amDuration.add(pmDuration).asHours();
	}

	public get differenceBetweenActualAndRequired(): number {
		return this.actualHours - this.requiredHours;
	}

	public isLawCompliant(previousDay: TimeTrackingDay | undefined): string[] {
		const lawViolations: string[] = [];

		if (this.untilAM.isBefore(this.fromAM, 'minute') || this.untilPM.isBefore(this.fromPM, 'minute')) {
			lawViolations.push("Uhrzeiten überprüfen");
		}

		if (this.actualHours > 10) {
			lawViolations.push("Max. 10 Stunden pro Tag");
		}

		const amDuration = moment.duration(this.untilAM.diff(this.fromAM));
		if ((amDuration.asHours() > 5) ||
			(moment.duration(this.fromPM.diff(this.untilAM)).asHours() === 0 && moment.duration(this.untilPM.diff(this.fromAM)).asHours() > 5)) {
			lawViolations.push("Pause nach 5 Stunden verpflichtend");
		}

		const pmDuration = moment.duration(this.untilPM.diff(this.fromPM));
		if (amDuration.add(pmDuration).asHours() > 5 && moment.duration(this.fromPM.diff(this.untilAM)).asHours() < 0.5) {
			lawViolations.push("Pause von mind. 30 min erforderlich");
		}

		//check if a previous day exists
		if(previousDay !== undefined){
			const nightDuration = moment.duration(this.fromAM.diff(previousDay.untilPM));
			if(nightDuration.asHours()<12 && this.actualHours > 0 && previousDay.actualHours > 0){
				lawViolations.push("Mind. 12 Stunden zwischen 2 Arbeitstagen");
			}
		}

		return lawViolations;
	}

	public get usesZAString(): string {
		if (this.usesZa === 0) {
			return 'ZA';
		} else if (this.usesZa === 1) {
			return 'Urlaub';
		} else if (this.usesZa === 2) {
			return 'Krankenstand';
		}

		return '';
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(values: Record<string, any> = {}) {
		Object.assign(this, values);

		this.date = moment(values.date);
		this.fromAM = moment(values.fromAM);
		this.untilAM = moment(values.untilAM);
		this.fromPM = moment(values.fromPM);
		this.untilPM = moment(values.untilPM);
		this.requiredHours = values.requiredHours;
		this.usesZa = values.usesZa;
	}

	public isEqualTo(day: TimeTrackingDay): boolean {
		return (
			this.date.isSame(day.date, 'day') &&
			this.fromAM.isSame(day.fromAM, 'minute') &&
			this.untilAM.isSame(day.untilAM, 'minute') &&
			this.fromPM.isSame(day.fromPM, 'minute') &&
			this.untilPM.isSame(day.untilPM, 'minute') &&
			this.comment === day.comment &&
			this.usesZa === day.usesZa &&
			this.publicHoliday === day.publicHoliday &&
			this.requiredHours === day.requiredHours
		);
	}
}

export default TimeTrackingDay;
