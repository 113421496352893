import PublicHolidayModel from '../PublicHolidays/PublicHoliday.Model';
import AuthService from './AuthService';

class PublicHolidayService extends AuthService{
	public savePublicHoliday(publicHoliday: PublicHolidayModel): Promise<void> {
		return this.fetch<void>("/api/publicHolidays/savePublicHoliday",{
			method: "POST",
			body: JSON.stringify(publicHoliday)
		});
	}

	public getAllPublicHolidays(): Promise<PublicHolidayModel[]>{
		return this.fetch<PublicHolidayModel[]>("/api/publicHolidays/getAllPublicHolidays",{
			method: "POST"
		});
	}

	public deletePublicHoliday(name: string): Promise<void>{
		return this.fetch<void>("/api/publicHolidays/deletePublicHoliday",{
			method: "POST",
			body: JSON.stringify({publicHolidayNameToBeDeleted: name})
		});
	}
}

export default PublicHolidayService;