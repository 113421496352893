import React, { Component, ReactNode } from 'react';

import {
	ListItem,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import TimeTrackingMonth from '../Tracking/DataModels/TimeTrackingMonth';
import TimeTrackingDay from '../Tracking/DataModels/TimeTrackingDay';
import vacationEmployeeMonthStyles from './VacationEmployeeMonthStyles';

interface Props extends WithStyles<typeof vacationEmployeeMonthStyles> {
	timeTrackingMonth: TimeTrackingMonth;
}

class VacationEmployeeMonth extends Component<Props> {
	public constructor(props: Props) {
		super(props);
	}

	private getSumVacationDays(): number {
		let days = 0;
		for (const day of this.props.timeTrackingMonth.days) {
			if (day.usesZa === 1) {
				days++;
			}
		}
		return days;
	}

	private getSumVacatioDays(): number {
		let days = 0;
		for (const day of this.props.timeTrackingMonth.days) {
			if (day.usesZa === 2) {
				days++;
			}
		}
		return days;
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return(
			<ListItem className={classes.listItem} key={this.props.timeTrackingMonth.userEmail}>
				<ListItemText>
					<Typography variant="h5">{`${this.props.timeTrackingMonth.userName} (${this.props.timeTrackingMonth.userEmail})`}</Typography>
					<div>
						<Typography variant="subtitle2" className={classes.subtitle}>Summe Urlaubs- und Krankentage:</Typography>
						<Typography style={{marginTop: 8}} variant="body1">Summe Urlaubstage: <b>{this.getSumVacationDays()} Tage</b></Typography>
						<Typography variant="body1">Summe Krankentage: <b>{this.getSumVacatioDays()} Tage</b></Typography>

						<Typography variant="subtitle2" className={classes.subtitle}>Einzelne Urlaubs- und Krankentage:</Typography>
						<Table>
							<TableBody>
								{this.props.timeTrackingMonth.days.filter((day: TimeTrackingDay) => day.usesZa === 1 || day.usesZa === 2).map((day: TimeTrackingDay): ReactNode => (
									<TableRow key={day.date.toString()}>
										<TableCell>{day.date.format('DD. MM. YYYY')}</TableCell>
										{day.usesZa === 1 &&
											<TableCell align="right">{'Urlaub'}</TableCell>
										}
										{day.usesZa === 2 &&
											<TableCell align="right">{'Krankenstand'}</TableCell>
										}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</ListItemText>
			</ListItem>
		);
	}
}

export default withStyles(vacationEmployeeMonthStyles)(VacationEmployeeMonth);
