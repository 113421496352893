import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const tableFooterPrinterStyles = (theme: Theme): StyleRules<string> => createStyles({
	noBottomBorder: {
		borderBottom: "0px !important"
	}
});

export default tableFooterPrinterStyles;
