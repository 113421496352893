import React, { Component, ReactNode } from 'react';

import Utilities from '../../../Utilities';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import tableFooterPrinterStyles from './TableFooterPrinterStyles';

interface Props extends WithStyles<typeof tableFooterPrinterStyles>{
	actualSum: number;
	requiredSum: number;
}

class TableFooterPrinter extends Component<Props>{

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<tfoot>
				<tr>
					<td colSpan={3} className={classes.noBottomBorder} />
					<td colSpan={2}>Summen: </td>
					<td>{Utilities.printHours(this.props.requiredSum)}</td>
					<td>{Utilities.printHours(this.props.actualSum)}</td>
					<td>{Utilities.printHours(this.props.actualSum - this.props.requiredSum)}</td>
					<td colSpan={2} className={classes.noBottomBorder}/>
				</tr>
			</tfoot>
		);
	}
}

export default withStyles(tableFooterPrinterStyles)(TableFooterPrinter);
