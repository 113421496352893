import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';


const vacationMonthPrinterStyles = (_: Theme): StyleRules<string> =>
	createStyles({
		tableMonth: {
			pageBreakInside: 'avoid'
		},
		month: {
			padding: '4px'
		},
		monthValues: {
			display: 'flex',
			alignContent: 'flex-start',
			justifyContent: 'space-between',
			marginBottom: '8px'
		},
		vacationDays: {
			display: 'flex',
			alignContent: 'flex-start',
			columnGap: '8px'
		}
	});

export default vacationMonthPrinterStyles;
