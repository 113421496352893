import React, { Component, ReactNode } from 'react';

import {
	WithStyles,
	withStyles
} from '@material-ui/core';
import { ReactComponent as Logo } from '../../Authentication/logo.svg';

import TimeTrackingDay from '../../Tracking/DataModels/TimeTrackingDay';
import TimeTrackingMonth from '../../Tracking/DataModels/TimeTrackingMonth';

import VacationEmployeeMonthPrinter from './VacationEmployeeMonthPrinter';
import vacationEmployeesPrinterStyles from './VacationEmployeesPrinterStyles';

interface Props extends WithStyles<typeof vacationEmployeesPrinterStyles> {
	timeTrackingMonths: TimeTrackingMonth[];
	selectedYear: number;
	selectedMonth: number;
}

class VacationEmployeesPrinter extends Component<Props> {
	private months = ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<div className={`${classes.printerOverlay} bc-printable `}>
				<Logo className={classes.printLogo} />

				<span style={{right: "5%", position: "absolute"}}>ELEPHANTS 5, Hauptplatz 17, A-4050 Traun</span>

				<div className={this.props.classes.generalInfo} >
					<b>Urlaubs- und Krankentage Übersicht</b>
					<b>{this.months[this.props.selectedMonth] + ' ' + this.props.selectedYear}</b>
				</div>
				<div className={classes.root}>
					<table className={classes.table}>
						<tbody>
							{this.props.timeTrackingMonths.map((timeTrackingMonth: TimeTrackingMonth): ReactNode => {
								if (timeTrackingMonth.days.filter((day: TimeTrackingDay) => day.usesZa === 1 || day.usesZa === 2).length > 0) {
									return (
										<VacationEmployeeMonthPrinter key={timeTrackingMonth.userEmail}
											timeTrackingMonth={timeTrackingMonth}
										/>
									);
								} else {
									return <React.Fragment key={timeTrackingMonth.userEmail}></React.Fragment>;
								}
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default withStyles(vacationEmployeesPrinterStyles)(VacationEmployeesPrinter);
