import React, { Component, ReactNode } from 'react';

import {
	DialogActions,
	DialogContent,
	FormControl,
	Radio,
	RadioGroup,
	Typography,
	WithStyles,
	Button,
	FormControlLabel,
	withStyles
} from '@material-ui/core';

import loxoneTimesModalStyles from './LoxoneTimesModalStyles';

import moment, { Moment } from 'moment';
import { LoxoneRESTLog } from '../../Common/TimeTrackingService';


interface State {
	begin?: Moment;
	end?: Moment;
}

interface Props extends WithStyles<typeof loxoneTimesModalStyles> {
	closeHandler: Function;
	loxoneLogs: LoxoneRESTLog[];
}

class LoxoneTimesModal extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			begin: this.props.loxoneLogs[0].time,
			end: this.props.loxoneLogs[this.props.loxoneLogs.length - 1].time
		};
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (this.props.loxoneLogs.length > 0) {
			if (prevProps.loxoneLogs[0].time !== this.props.loxoneLogs[0].time || prevProps.loxoneLogs[prevProps.loxoneLogs.length - 1].time !== this.props.loxoneLogs[this.props.loxoneLogs.length - 1].time) {
				const begin = this.props.loxoneLogs[0].time;
				const end = this.props.loxoneLogs[this.props.loxoneLogs.length - 1].time;
				this.setState({begin, end});
			}
		}
	}

	public handleClose(save = false): void {
		if (save) {
			//saving worktime exception data
			this.props.closeHandler(this.state.begin, this.state.end);
		} else {
			this.props.closeHandler();
		}
	}

	public handleRadioButtonChange(e: React.ChangeEvent<{ value?: unknown }>, v: string, isBegin: boolean): void {
		if (isBegin) {
			this.setState({begin: moment(e.target.value as string)});
		} else {
			this.setState({end: moment(e.target.value as string)});
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div>
				<DialogContent className={classes.dialogBox}>
					<Typography variant={"h6"}>
						Loxone-Zeiten vom <br/>
						{ this.getDate() }
					</Typography>
					<FormControl component="fieldset" className={classes.loxoneTimeSelector}>
						<Typography variant={'subtitle2'}>Start</Typography>
						<RadioGroup onChange={(e, v): void => this.handleRadioButtonChange(e, v, true)}>
							{
								this.printLoxoneTimes(true)
							}
						</RadioGroup>
					</FormControl>

					<FormControl component="fieldset" className={classes.loxoneTimeSelector}>
						<Typography variant={'subtitle2'}>Ende</Typography>
						<RadioGroup onChange={(e, v): void => this.handleRadioButtonChange(e, v, false)}>
							{
								this.printLoxoneTimes(false)
							}
						</RadioGroup>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.handleClose.bind(this, false, false)}
						className={this.props.classes.secondaryButton}
					>
            Abbrechen
					</Button>
					<Button
						onClick={this.handleClose.bind(this, true, false)}
						className={this.props.classes.primaryButton}
					>
            Speichern
					</Button>
				</DialogActions>
			</div>
		);
	}

	public getDate(): string {
		if (this.state.begin !== undefined) {
			return this.state.begin.format("DD.MM.YYYY");
		}

		return '';
	}

	private printLoxoneTimes(isBeginTime: boolean): ReactNode[] {
		const timeNodes: ReactNode[] = [];

		this.props.loxoneLogs.forEach((log: LoxoneRESTLog, index: number): void => {
			let checked = false;
			const firstItem = isBeginTime ? this.state.begin : this.state.end;
			if (firstItem) {
				checked = firstItem.isSame(log.time, 'seconds');
			}

			timeNodes.push(
				<FormControlLabel key={index} checked={checked} value={log.time.toISOString()} control={<Radio />} label={log.time.format("HH:mm:ss")} />
			);
		});

		return timeNodes;
	}
}

export default withStyles(loxoneTimesModalStyles)(LoxoneTimesModal);
