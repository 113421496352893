import { Theme, createStyles } from "@material-ui/core";
import { StyleRules } from '@material-ui/core/styles';


const vacationMonthStyles = (_: Theme): StyleRules<string> =>
	createStyles({
		listItem: {
			margin: '48px 60px',
			width: 'auto'
		},
		monthValues: {
			display: 'flex',
			alignContent: 'flex-start',
			justifyContent: 'space-between',
			margin: '6px 0'
		},
		vacationType: {
			marginTop: '24px'
		}
	});

export default vacationMonthStyles;
