import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const publicHolidayStyles = (_: Theme): StyleRules<string> => createStyles({
	primaryButton: {
		backgroundColor: "#131a2e",
		color: "white",
		"&:hover":{
			backgroundColor: "#07070e",
			color: "white"
		}
	},
	secondaryButton: {
		backgroundColor: "#375184",
		color: "white",
		"&:hover":{
			backgroundColor: "#12122b",
			color: "white"
		}
	},
	dialogBox: {
		height: "fit-content",
		width: "fit-content"
	},
	newUserButton: {
		padding: "10px",
		margin: "10px",
		backgroundColor: "secondary"
	},
	table: {
		width: "100%"
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start'
	}
});

export default publicHolidayStyles;
