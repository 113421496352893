import React, { ReactNode, Component, ChangeEvent, MouseEvent } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { WithStyles, withStyles } from "@material-ui/core";
import { ReactComponent as Logo } from "./logo.svg";

import loginStyles from "./LoginStyles";

import AuthService from "../Common/AuthService";

interface Props extends WithStyles<typeof loginStyles>, RouteComponentProps {}

interface State {
	password: string;
	email: string;
}

class Login extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.authService = new AuthService();
	}

	public render(): ReactNode {
		if (this.authService.loggedIn()) {
			return <Redirect to={{ pathname: "/tracking" }} />;
		}

		const classes = this.props.classes;

		return (
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<Logo className={classes.logo} />

					<form className={classes.form} noValidate>
						<Typography component="h1" variant="h5">
							{"Zeiterfassung Login"}
						</Typography>
						<TextField
							variant="standard"
							margin="normal"
							required
							fullWidth
							id="Email"
							label="Email"
							name="email"
							onChange={this.handleChange}
							autoFocus
						/>
						<TextField
							variant="standard"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							onChange={this.handleChange}
							autoComplete="current-password"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="secondary"
							className={classes.submit}
							onClick={this.handleFormSubmit}
						>
							{"Login"}
						</Button>
					</form>
				</div>
			</Container>
		);
	}

	private handleChange(e: ChangeEvent<HTMLInputElement>): void {
		if (e.target.name === "email") {
			this.setState({ email: e.target.value });
		} else if (e.target.name === "password") {
			this.setState({ password: e.target.value });
		}
	}

	private handleFormSubmit(e: MouseEvent<HTMLElement>): void {
		e.preventDefault();

		this.authService
			.login(this.state.email, this.state.password)
			.then((response: Response): void => {
				this.props.history.replace("/");
			})
			.catch((error: Error): void => {
				alert(error);
			});
	}
}

export default withStyles(loginStyles)(Login);
