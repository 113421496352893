import React, { Component, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';

import {
	Button,
	Divider,
	Fab,
	FormControl,
	InputLabel,
	List,
	MenuItem,
	Select,
	Typography,
	Paper,
	WithStyles,
	withStyles
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

import moment from 'moment';

import VacationEmployeesPrinter from './Printer/VacationEmployeesPrinter';
import TimeTrackingDay from '../Tracking/DataModels/TimeTrackingDay';
import TimeTrackingMonth from '../Tracking/DataModels/TimeTrackingMonth';
import { UserDataModel } from '../Admin/UserData.Model';

import TimeTrackingService from '../Common/TimeTrackingService';

import VacationEmployeeMonth from './VacationEmployeeMonth';
import vacationEmployeesStyles from './VacationEmployeesStyles';

interface State {
	selectedUser: UserDataModel | null;
	selectedMonth: number;
	selectedYear: number;
	selectableYears: number[];
	loading: boolean;
	timeTrackingMonths: TimeTrackingMonth[];
	refreshButtonClicked: boolean;
}

interface Props extends WithStyles<typeof vacationEmployeesStyles>, RouteComponentProps {
}

class VacationEmployees extends Component<Props, State> {
	private timeTrackingService: TimeTrackingService;

	public constructor(props: Props) {
		super(props);
		this.timeTrackingService = new TimeTrackingService();

		this.state = {
			selectedUser: null,
			selectedMonth: moment().month(),
			selectedYear: moment().year(),
			selectableYears: [],
			loading: false,
			timeTrackingMonths: [],
			refreshButtonClicked: false
		};
	}

	public async componentDidMount(): Promise<void> {
		await this.loadSelectableYears();
	}

	private selectChanged(event: React.ChangeEvent<{ name?: string; value: unknown }>, _: ReactNode,selectionType: string): void {
		if (selectionType === "month") {
			this.setState({selectedMonth: parseInt(event.target.value as string)});
		} else if(selectionType === "year") {
			this.setState({selectedYear: parseInt(event.target.value as string)});
		}
	}

	private async loadSelectableYears(): Promise<void> {
		const years: number[] = [];
		const timeTrackingMonths = await this.timeTrackingService.getAllTrackedTimes();

		for (const timeTrackingMonth of timeTrackingMonths) {
			if (!years.includes(timeTrackingMonth.year)) {
				years.push(timeTrackingMonth.year);
			}
		}

		this.setState({selectableYears: years});
	}

	private async loadTrackedTimes(): Promise<void> {
		this.setState({loading: true});
		const selectedMonth = moment();
		selectedMonth.set('year', this.state.selectedYear);
		selectedMonth.set('month', this.state.selectedMonth);
		const timeTrackingMonths = await this.timeTrackingService.getMultipleTrackedTimes(selectedMonth);
		this.setState({loading: false, timeTrackingMonths: timeTrackingMonths});
	}

	private loadEmployeesVacations(): void {
		this.loadTrackedTimes();
		this.setState({refreshButtonClicked: true});
	}

	private monthWithVacationDays(): boolean {
		let hasVacationDays = false;
		for (const timeTrackingMonth of this.state.timeTrackingMonths) {
			if (timeTrackingMonth.days.filter((day: TimeTrackingDay) => day.usesZa === 1 || day.usesZa === 2).length > 0) {
				hasVacationDays = true;
				break;
			}
		}
		return hasVacationDays;
	}

	public render(): ReactNode {

		return (
			<>
				<VacationEmployeesPrinter
					timeTrackingMonths={this.state.timeTrackingMonths}
					selectedYear={this.state.selectedYear}
					selectedMonth={this.state.selectedMonth}
				/>
				<div className={"bc-unprintable"}>
					<div className={this.props.classes.headerContainer}>
						<h1>Urlaubs- und Krankentage Übersicht</h1>
						<div className={this.props.classes.filterContainer}>
							<FormControl
								style={{width: "150px", marginRight: "16px"}}
								variant='outlined'
							>
								<InputLabel>Monat</InputLabel>
								<Select
									label={'Monat'}
									value={this.state.selectedMonth}
									onChange={(e,c): void => this.selectChanged(e, c, "month")}
								>
									<MenuItem key={"month_" + 0} value={0}>Jänner</MenuItem>
									<MenuItem key={"month_" + 1} value={1}>Februar</MenuItem>
									<MenuItem key={"month_" + 2} value={2}>März</MenuItem>
									<MenuItem key={"month_" + 3} value={3}>April</MenuItem>
									<MenuItem key={"month_" + 4} value={4}>Mai</MenuItem>
									<MenuItem key={"month_" + 5} value={5}>Juni</MenuItem>
									<MenuItem key={"month_" + 6} value={6}>Juli</MenuItem>
									<MenuItem key={"month_" + 7} value={7}>August</MenuItem>
									<MenuItem key={"month_" + 8} value={8}>September</MenuItem>
									<MenuItem key={"month_" + 9} value={9}>Oktober</MenuItem>
									<MenuItem key={"month_" + 10} value={10}>November</MenuItem>
									<MenuItem key={"month_" + 11} value={11}>Dezember</MenuItem>
								</Select>
							</FormControl>
							<FormControl
								style={{width: "150px"}}
								variant='outlined'>
								<InputLabel>Jahr</InputLabel>
								<Select
									label={'Jahr'}
									value={this.state.selectedYear}
									onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>,c: ReactNode): void =>this.selectChanged(e,c,"year")}
								>
									{
										this.state.selectableYears.map((year: number): ReactNode =>(
											<MenuItem key={"year_"+year} value={year}>{year}</MenuItem>
										))
									}
								</Select>
							</FormControl>
							<Button
								variant="contained"
								color="secondary"
								className={this.props.classes.reloadButton}
								onClick={this.loadEmployeesVacations.bind(this)}
							>
							Aktualisieren
							</Button>
						</div>
					</div>
					{this.monthWithVacationDays() &&
						<Paper>
							<List>
								{this.state.timeTrackingMonths.map((timeTrackingMonth: TimeTrackingMonth): ReactNode => {
									if (timeTrackingMonth.days.filter((day: TimeTrackingDay) => day.usesZa === 1 || day.usesZa === 2).length > 0) {
										return (
											<React.Fragment key={timeTrackingMonth.userEmail}>
												<VacationEmployeeMonth
													timeTrackingMonth={timeTrackingMonth}
												/>
												<Divider />
											</React.Fragment>
										);
									} else {
										return <React.Fragment key={timeTrackingMonth.userEmail}></React.Fragment>;
									}
								})}
							</List>
						</Paper>
					}
					{!this.monthWithVacationDays() && this.state.refreshButtonClicked && !this.state.loading &&
						<Typography variant="h5">Keine Urlaubs- und/oder Krankentage in diesem Monat!</Typography>
					}
					<Fab
						color="secondary"
						className={this.props.classes.fabPrint}
						onClick={async (): Promise<void> => {
							window.print();
						}}
					>
						<PrintIcon />
					</Fab>
				</div>
			</>
		);
	}

}

export default withStyles(vacationEmployeesStyles)(VacationEmployees);
