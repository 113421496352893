import createMuiTheme, { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export default function createZeiterfassungTheme(options: ThemeOptions): Theme {
	return createMuiTheme({
		palette: {
			primary: {
				main: '#131b2e',
			},
			secondary: {
				main: '#009fba',
				contrastText: '#ffffff'
			},error: {
				main: '#920000',
				contrastText: '#ffffff'
			},
		},
		...options,
	});
}
