import React, { Component, ReactNode } from 'react';

import { WithStyles, withStyles } from '@material-ui/core';

import vacationPrinterStyles from './VacationPrinterStyles';

import { ReactComponent as Logo } from '../../Authentication/logo.svg';
import { UserDataModel } from './../../Admin/UserData.Model';
import TimeTrackingMonth from '../../Tracking/DataModels/TimeTrackingMonth';
import VacationMonthPrinter from './VacationMonthPrinter';

interface Props extends WithStyles<typeof vacationPrinterStyles> {
	userData: UserDataModel | null;
	timeTrackingMonthsFiltered: TimeTrackingMonth[];
	timeTrackingMonths: TimeTrackingMonth[];
}

class TrackingPrinter extends Component<Props> {

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={`${classes.printerOverlay} bc-printable `}>

				<Logo className={classes.printLogo} />

				<span style={{right: "5%", position: "absolute"}}>ELEPHANTS 5, Hauptplatz 17, A-4050 Traun</span>

				<div className={this.props.classes.generalInfo} >
					<b>Urlaubsübersicht</b>
					<b>{`${this.props.userData?.firstName} ${this.props.userData?.lastName}`}</b>
					<b>Eintrittstag: {(this.props.userData && this.props.userData.entryDate) ? new Date(this.props.userData.entryDate).toLocaleDateString('de-DE', {month: '2-digit', day: '2-digit', year: 'numeric'}) : 'Nicht eingetragen'}</b>
				</div>
				<div className={classes.root}>
					<table>
						<tbody>
							{this.props.timeTrackingMonthsFiltered.map((timeTrackingMonth: TimeTrackingMonth): ReactNode => (
								<>
									<VacationMonthPrinter
										timeTrackingMonth={timeTrackingMonth}
										userData={this.props.userData}
										timeTrackingMonths={this.props.timeTrackingMonths}
									/>
								</>
							))}
						</tbody>
					</table>
				</div>

			</div>
		);
	}
}

export default withStyles(vacationPrinterStyles)(TrackingPrinter);
