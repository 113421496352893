import React, { Component, ReactNode } from 'react';

import { TextField, Tooltip, WithStyles, withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// TODO: remove usage of AppStyles, create own file if necessary
import trackingTableRowStyles from './TrackingTableRowStyles';
// TODO: combine all imports
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';

import { KeyboardTimePicker } from '@material-ui/pickers';

import moment from 'moment';
import Select from '@material-ui/core/Select';
import Utilities from '../../Utilities';
import TimeTrackingDay, { TimeTrackingTimeData } from '../DataModels/TimeTrackingDay';

interface Props extends WithStyles<typeof trackingTableRowStyles> {
	// TODO: this component also needs previous days data to check if law compliant
	currentDay: TimeTrackingDay;
	showSnackbar: Function;
	changeHandler: Function;
	previousDay?: TimeTrackingDay;
	trackingLoxoneImport: Function;
}



class TrackingTableRow extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public shouldComponentUpdate(nextProps: Props): boolean {
		let previousDayChanged = false;

		if(this.props.previousDay && nextProps.previousDay){
			previousDayChanged = !this.props.currentDay.isEqualTo(nextProps.currentDay) ||
				!this.props.previousDay.isEqualTo(nextProps.previousDay);
		}

		return (
			!this.props.currentDay.isEqualTo(nextProps.currentDay) || previousDayChanged
		);
	}

	private static options = ["Vom Vortag Kopieren", "Loxone import"];
	private static usesZaOptions = ["ZA", "Urlaub", "Krankenstand"];

	public render(): ReactNode {
		const todaysData = this.props.currentDay;
		const lawCompliance = todaysData.isLawCompliant(this.props.previousDay);

		// TODO: why do all cells have the same class?
		// TODO: remove inline css
		// TODO: make a class variable before the return that contains the classes for a specific element, don't do it inline
		return (
			<TableRow className={
				`${ todaysData.isDayOff ? this.props.classes.dayOff : "" }
				 ${lawCompliance.length === 0 ? "" : this.props.classes.exceededWorkingHours }
				 ${ moment(todaysData.date).isSame(moment(), 'day') ? this.props.classes.currentDay : this.props.classes.regularDay }`}>

				<TableCell className={this.props.classes.tableCell} >
					{
						this.printErrors(lawCompliance)
					}
					{moment(todaysData.date).format("dddd, DD.MM.YYYY")}
				</TableCell>
				<TableCell className={this.props.classes.tableCell} >
					{this.generateKeyboardTimepicker("fromAM")}
				</TableCell>
				<TableCell className={this.props.classes.tableCell} >
					{this.generateKeyboardTimepicker("untilAM")}
				</TableCell>
				<TableCell className={this.props.classes.tableCell} >
					{this.generateKeyboardTimepicker("fromPM")}
				</TableCell>
				<TableCell className={this.props.classes.tableCell} >
					{this.generateKeyboardTimepicker("untilPM")}
				</TableCell>
				<TableCell className={this.props.classes.tableCell} >
					{Utilities.printHours(todaysData.requiredHours)}
				</TableCell>
				<TableCell className={this.props.classes.tableCell} >
					{Utilities.printHours(todaysData.actualHours)}
				</TableCell>
				<TableCell className={`${(todaysData.differenceBetweenActualAndRequired) < 0 ? this.props.classes.negativeHours : undefined} ${this.props.classes.tableCell}`}>
					{Utilities.printHours(todaysData.differenceBetweenActualAndRequired)}
				</TableCell>
				<TableCell className={this.props.classes.tableCell}>
					<Select
						variant={'outlined'}
						inputProps={{
							name: 'Monat',
							id: 'month-simple',
						}}
						value={todaysData.usesZa}
						onChange={(event): void => { this.handleSelectChange(event); }}
						className={this.props.classes.selectMenu}
					>
						{TrackingTableRow.usesZaOptions.map((option: string, index: number): ReactNode => (
							<MenuItem value={index} key={index}>{option}</MenuItem>
						))}
					</Select>
				</TableCell>
				<TableCell className={this.props.classes.tableCell} >
					<TextField
						value={todaysData.comment}
						fullWidth
						style={{width: "150px"}}
						onChange={(event): void => { this.handleCommentChange(event); }}
					/>
				</TableCell>
				<TableCell className={this.props.classes.tableCell}>
					<PopupState variant="popover" popupId="edit-exceptions-menu">
						{(popupState: Window): ReactNode => (
							<React.Fragment>
								<IconButton {...bindTrigger(popupState)}>
									<MoreVertIcon />
								</IconButton>
								<Menu {...bindMenu(popupState)}>
									{TrackingTableRow.options.map((option): ReactNode =>(
										<MenuItem
											key={option}
											onClick={ (): void => {
												if (option === "Loxone import") {
													this.props.trackingLoxoneImport(this.props.currentDay);
												} else {
													this.triggerCopyFromPreviousDay();
													this.setSnackbar("Vom Vortag kopiert");
												}
												popupState.close();
											}}
										>
											{option}
										</MenuItem>
									))}
								</Menu>
							</React.Fragment>
						)}
					</PopupState>
				</TableCell>
			</TableRow>
		);
	}

	private generateKeyboardTimepicker(property: keyof TimeTrackingTimeData): ReactNode{
		return (
			<KeyboardTimePicker
				ampm={false}
				style={{width: "100px"}}
				variant="inline"
				value={this.props.currentDay[property]}
				onChange={(event:  Date | null): void => { this.handleTimeChange(event, property); }}
			/>
		);
	}

	private triggerCopyFromPreviousDay(): void {
		const changedTrackingDay = new TimeTrackingDay(this.props.currentDay);
		const previousDay = this.props.previousDay;

		if(!!previousDay){
			changedTrackingDay.fromAM.hour(previousDay.fromAM.hour()).minute(previousDay.fromAM.minute());
			changedTrackingDay.untilAM.hour(previousDay.untilAM.hour()).minute(previousDay.untilAM.minute());
			changedTrackingDay.fromPM.hour(previousDay.fromPM.hour()).minute(previousDay.fromPM.minute());
			changedTrackingDay.untilPM.hour(previousDay.untilPM.hour()).minute(previousDay.untilPM.minute());

			this.props.changeHandler(changedTrackingDay);
		}
	}

	private setSnackbar(message: string): void{
		this.props.showSnackbar("[" + moment(this.props.currentDay.date).format("dddd, DD.MM.YYYY")+"] " + message);
	}

	private handleTimeChange(dateEvent: Date | null, property: keyof TimeTrackingTimeData): void {
		if (dateEvent !== null && !isNaN(dateEvent.valueOf())) {
			const timeMoment = moment(dateEvent);
			const dateMoment = this.props.currentDay.date.clone().set({hour: timeMoment.hour(), minute: timeMoment.minute()});

			const newTrackingDay = new TimeTrackingDay(this.props.currentDay);
			newTrackingDay[property] = dateMoment;
			this.props.changeHandler(newTrackingDay);
		}
	}

	private handleCommentChange(
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
	): void {
		const newTrackingDay = new TimeTrackingDay(this.props.currentDay);
		newTrackingDay.comment = event.target.value;
		this.props.changeHandler(newTrackingDay);
	}

	private handleSelectChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
		const newTrackingDay = new TimeTrackingDay(this.props.currentDay);
		newTrackingDay.usesZa = event.target.value as number;
		this.props.changeHandler(newTrackingDay);
	}

	private printErrors(lawCompliance: string[]): ReactNode | undefined {
		if(lawCompliance.length > 0){

			const errors: ReactNode = (
				<React.Fragment>
					{
						lawCompliance.map((errmsg: string, index: number): ReactNode => (<p key={index}>{errmsg}</p>))
					}
				</React.Fragment>
			);

			return (
				<Tooltip title={errors} placement="bottom">
					<ErrorIcon className={this.props.classes.worktimeErrorIcon} />
				</Tooltip>
			);
		}
	}
}

export default withStyles(trackingTableRowStyles)(TrackingTableRow);
