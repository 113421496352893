import React, { Component, ReactNode } from 'react';

import { TableCell, TableFooter, TableRow, withStyles, WithStyles } from '@material-ui/core';

// TODO: remove use of AppStyles, if needed create new file
import trackingTableFooterStyles from './TrackingTableFooterStyles';
import Utilities from '../../Utilities';

interface Props extends WithStyles<typeof trackingTableFooterStyles> {
	actualSum: number;
	requiredSum: number;
}

class TrackingTableFooter extends Component<Props>{
	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		return (
			<TableFooter>
				<TableRow>
					<TableCell colSpan={4}/>
					<TableCell>Summen: </TableCell>
					<TableCell>{Utilities.printHours(this.props.requiredSum)}</TableCell>
					<TableCell>{Utilities.printHours(this.props.actualSum)}</TableCell>
					<TableCell>{Utilities.printHours(this.props.actualSum-this.props.requiredSum)}</TableCell>
					<TableCell colSpan={3}/>
				</TableRow>
			</TableFooter>
		);
	}
}

export default withStyles(trackingTableFooterStyles)(TrackingTableFooter);
