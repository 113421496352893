import { Theme, createStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const adminStyles = (_: Theme): StyleRules<string> => createStyles({
	table: {
		width: "100%"
	},
	newUserButton: {
		padding: "10px",
		margin: "10px",
		backgroundColor: "secondary"
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start'
	}
});

export default adminStyles;
