import TimeTrackingDay from './TimeTrackingDay';
import { WorkingWeekdays, SpecialVacation } from '../../Admin/UserData.Model';
import moment from 'moment';



export class TimeTrackingMonth {
	public month = 0;
	public year = 0;
	public firstDay?: Date;

	public overtimeBalance = 0;
	public vacationBalance = 0;
	public vacationYearBalance = 0;
	public days: TimeTrackingDay[] = [];

	public userName = '';
	public userEmail = '';

	public get actualSum(): number {
		let actualSum = 0;

		if (this.days) {
			for (const day of this.days) {
				if (day.actualHours !== undefined) {
					actualSum += day.actualHours;
				}
			}
		}

		return actualSum;
	}

	public get requiredSum(): number {
		let requiredSum = 0;

		if (this.days) {
			for (const day of this.days) {
				if (day.requiredHours !== undefined && day.usesZa === 0) {
					requiredSum += day.requiredHours;
				}
			}
		}

		return requiredSum;
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	public constructor(values: any = {}) {
		Object.assign(this, values);

		this.days = [];
		if (values.days) {
			for (const day of values.days) {
				this.days.push(new TimeTrackingDay(day));
			}
		}
	}

	// TODO: implement methods for monthly sums (ZA/Vacation Days)
	// This method needs to accept last months data for starting its computation
	public getUsedVacationThisMonth(workingWeekdaysArray: WorkingWeekdays[] | undefined, specialVacations: SpecialVacation[] | undefined): number{
		const sum = this.getConsumedVacationDaysThisMonth();
		const vacationDays = this.getVacationDaysThisMonth(workingWeekdaysArray);
		const specialVacationDays = this.getSpecialVacationDaysThisMonth(specialVacations);

		return vacationDays + specialVacationDays - sum;
	}



	public getConsumedVacationDaysThisMonth(): number {
		let sum = 0;
		this.days.forEach((day: TimeTrackingDay): void => {
			if (day.usesZa === 1) {
				sum++;
			}
		});

		return sum;
	}

	public getSpecialVacationDaysThisMonth(specialVacations: SpecialVacation[] | undefined): number {
		let specialVacationDays = 0;
		if (specialVacations) {
			for (const specialVacation of specialVacations) {
				if (this.year === new Date(specialVacation.date).getFullYear() && this.month === new Date(specialVacation.date).getMonth()) {
					specialVacationDays = specialVacation.days;
					break;
				}
			}
		}
		return specialVacationDays;
	}

	public getVacationYearDaysToAdd(workingWeekdays: WorkingWeekdays[] | undefined, entryDate: Date | undefined, timeTrackingMonths: TimeTrackingMonth[], type: string): number {
		let yearVacationToAdd = 0;
		if (workingWeekdays && entryDate) {
			for (let i = 0; i < workingWeekdays.length; i++) {
				// Calculate offset if number of working weekdays changed
				if (i > 0 && this.month === new Date(workingWeekdays[i].startDate).getMonth() && this.year === new Date(workingWeekdays[i].startDate).getFullYear()) {
					const currentNumberOfWeekdays = this.getWorkingDaysPerWeek(workingWeekdays[i].weekdays);
					const previousNumberOfWeekdays = this.getWorkingDaysPerWeek(workingWeekdays[i-1].weekdays);

					if ((type === 'add' && currentNumberOfWeekdays > previousNumberOfWeekdays) || (type === 'subtract' && currentNumberOfWeekdays < previousNumberOfWeekdays) || (type === 'all')) {

						// Get next entry date in future
						const firstOfMonth = new Date(this.year, this.month);
						const futureEntryDate = new Date(this.getFutureEntryDate(entryDate, firstOfMonth));
						let remainingVacation = 0;

						// Calculate remaining vacation up to next future entry date
						for (const timeTrackingMonthOffsetCalc of timeTrackingMonths) {
							const workingWeekdaysStartDateFirstOfMonth = new Date(workingWeekdays[i].startDate);
							workingWeekdaysStartDateFirstOfMonth.setDate(1);
							const futureEntryDateFirstOfMonth = new Date(futureEntryDate.getFullYear(), futureEntryDate.getMonth());
							futureEntryDateFirstOfMonth.setDate(1);

							if (new Date(timeTrackingMonthOffsetCalc.year, timeTrackingMonthOffsetCalc.month) >= workingWeekdaysStartDateFirstOfMonth && new Date(timeTrackingMonthOffsetCalc.year, timeTrackingMonthOffsetCalc.month) <= futureEntryDateFirstOfMonth) {

								let numberOfDaysBetweenStartAndEntryDate = 0;

								for (const day of timeTrackingMonthOffsetCalc.days) {
									const dayDate = new Date(day.date.year(), day.date.month(), day.date.date());
									if (dayDate >= new Date(workingWeekdays[1].startDate) && dayDate < futureEntryDate) {
										numberOfDaysBetweenStartAndEntryDate++;
									}
								}

								if (workingWeekdays[i-1].vacationDays === 0) {
									remainingVacation += (numberOfDaysBetweenStartAndEntryDate * 25) / 365;
								} else {
									remainingVacation += (numberOfDaysBetweenStartAndEntryDate * workingWeekdays[i-1].vacationDays) / 365;
								}
							}
						}

						let remainingVacationConverted = 0;
						if (workingWeekdays[i-1].vacationDays < workingWeekdays[i].vacationDays) {
							remainingVacationConverted = Math.floor(remainingVacation);
						} else {
							remainingVacationConverted = Math.ceil((workingWeekdays[i].vacationDays * remainingVacation) / (workingWeekdays[i-1].vacationDays) - remainingVacation);
						}

						if (currentNumberOfWeekdays !== previousNumberOfWeekdays) {
							yearVacationToAdd += remainingVacationConverted;
						}
					}
				}
			}
		}

		return yearVacationToAdd;

	}

	public getYearlyAddedVacationYearDays(workingWeekdays: WorkingWeekdays[] | undefined, entryDate: Date | undefined): number {
		let yearVacationToAdd = 0;
		if (workingWeekdays && entryDate) {
			for (let i = 0; i < workingWeekdays.length; i++) {

				// Yearly added vacation on entry date month
				if (this.month === new Date(entryDate).getMonth() && new Date(this.year, this.month, new Date(workingWeekdays[i].startDate).getDate()) >= new Date(workingWeekdays[i].startDate) && new Date(this.year, this.month) <= new Date(workingWeekdays[i].endDate)) {
					yearVacationToAdd += workingWeekdays[i].vacationDays;
					// Prevent vacation days to be added two times if entry date and start date of working weekday have same month
					if (workingWeekdays[i] && workingWeekdays[i-1] && this.getWorkingDaysPerWeek(workingWeekdays[i].weekdays) !== this.getWorkingDaysPerWeek(workingWeekdays[i-1].weekdays) && new Date(workingWeekdays[i].startDate).getMonth() === new Date(entryDate).getMonth() && this.year <= new Date(workingWeekdays[i].startDate).getFullYear()) {
						yearVacationToAdd -= workingWeekdays[i].vacationDays;
					}
				}
			}
		}
		return yearVacationToAdd;
	}

	private getWorkingDaysPerWeek(weekdays: number[]): number {
		let daysPerWeek = 0;
		for (const day of weekdays) {
			if (day !== 0) {
				daysPerWeek++;
			}
		}
		return daysPerWeek;
	}

	public getDaysInYear(year: number): number {
		let daysInYear = 0;
		for (let month = 1; month < 13; month++) {
			const yearStr = '' + year;
			let monthStr = '';
			if (month < 10) {
				monthStr = '0' + month;
			} else {
				monthStr += month;
			}
			daysInYear += moment(yearStr + '-' + monthStr, "YYYY-MM").daysInMonth();
		}
		return daysInYear;
	}

	public getVacationDaysThisMonth(workingWeekdaysArray: WorkingWeekdays[] | undefined): number {
		let vacationDays = 0;
		if (workingWeekdaysArray) {
			const daysInYear = this.getDaysInYear(this.year);
			for (const workingWeekdays of workingWeekdaysArray) {

				for (const day of this.days) {
					const dayDate = new Date(day.date.toDate());
					if (dayDate >= new Date(workingWeekdays.startDate) && dayDate <= new Date(workingWeekdays.endDate)) {
						vacationDays += (workingWeekdays.vacationDays / daysInYear);
					}
				}
			}
		}
		return vacationDays;
	}

	private getFutureEntryDate(entryDate: Date, currentFirstOfMonth: Date): Date {
		const entryDateAsDate = new Date(entryDate);
		const entryDateFirstOfMonth = new Date(entryDateAsDate.getFullYear(), entryDateAsDate.getMonth());

		while (entryDateFirstOfMonth <= currentFirstOfMonth) {
			entryDateFirstOfMonth.setFullYear(entryDateFirstOfMonth.getFullYear() + 1);
		}
		entryDateFirstOfMonth.setDate(entryDateAsDate.getDate());
		return entryDateFirstOfMonth;
	}

	public get totalHoursThisMonth(): number{
		let sum = 0;

		for (const day of this.days) {
			if (day.usesZa === 0 || day.usesZa === 1) {
				sum += day.actualHours;
			}
		}

		return sum;
	}

	public get overtimeChangeThisMonth(): number {
		let sum = 0;

		this.days.forEach((day: TimeTrackingDay): void => {
			if(day.usesZa === 0) {
				sum += day.actualHours - day.requiredHours;
			}
			if(day.usesZa === 1) {
				sum += day.actualHours;
			}
		});

		return sum;
	}
}

export default TimeTrackingMonth;
