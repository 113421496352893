import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

import './index.css';

import AppRouter from './App/AppRouter';
// TODO: Rename to AppTheme or something similiar (remove Zeiterfassung)
import createZeiterfassungTheme from './App/Theme';

const ZeiterfassungTheme = createZeiterfassungTheme({});

ReactDOM.render((
	<React.Fragment>
		<CssBaseline />
		<ThemeProvider theme={ZeiterfassungTheme}>
			<AppRouter />
		</ThemeProvider>
	</React.Fragment>
), document.getElementById('root'));
