import React, { ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import AuthService from '../Common/AuthService';

interface PrivateRouteProps extends RouteProps{
	mustBeAdmin: boolean;
	path: string;
}

class PrivateRoute extends Route<PrivateRouteProps> {
	private authService: AuthService;

	public constructor(props: PrivateRouteProps) {
		super(props);
		this.authService = new AuthService();
	}

	public render(): ReactNode {

		if (!this.authService.loggedIn()) {
			return (
				<Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
			);
		}

		if(this.props.mustBeAdmin && !this.authService.isAdmin()){
			return (
				<Redirect to={{ pathname: this.props.path, state: { from: this.props.location } }} />
			);
		}

		return super.render();
	}
}

export default PrivateRoute;
